import React, { useContext } from 'react';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import OrderRiderStatus from 'components/common/verd/OrderRiderStatus';

const StoreRiderInfo = ({ deliveryOrderStatus, orderDeliveryPhoneNumber }) => {
  const { lang } = useContext(localeContext);

  return (
    <div className="bg-gray-200 mb-4">
      <div className="bg-gray-200 py-2 px-4">
        <Text value={translations.RIDER_INFO} className="font-medium" />
      </div>
      <div className="flex flex-row justify-between px-2 py-4 mx-1 mb-1 bg-white">
        <div className="flex flex-col justify-between">
          <Text value={translations.REQUEST_STATUS} className="text-xs text-zyda-grey-400" />
          <OrderRiderStatus status={deliveryOrderStatus} lang={lang} />
        </div>
        <div className="flex  flex-col justify-between">
          <Text value={translations.PHONE_NUMBER} className="text-xs text-zyda-grey-400" />
          <span className="text-xs text-primary-base">{orderDeliveryPhoneNumber}</span>
        </div>
      </div>
    </div>
  );
};

export default StoreRiderInfo;
