import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BullhornIcon } from '@zydalabs/zac-icons-react';
import { useLocation } from '@reach/router';

import { Field, Text } from 'components/service';
import { Input } from 'components/form/elements';
import { Label } from 'components/form/generic';
import { context as userContext } from 'context/user';
import { context as customersContext } from 'context/customers';
import { context as featureAccessContext } from 'context/feature-blocking';
import { ReactComponent as RetentionCampaignIcon } from 'assets/retention-campaign-refresh.svg';
import { ReactComponent as SegmentTargetingCampaignIcon } from 'assets/segment-targeting-users.svg';
import {
  RETENTION,
  RETENTION_DESCRIPTION,
  SEGMENT_TARGETING,
  SEGMENT_TARGETING_DESCRIPTION,
  CAMPAIGN_TITLE,
  EX_CHECK_OUR_OFFERS,
  ANNOUNCEMENT,
  ANNOUNCEMENT_DESCRIPTION,
  SMART_CAMPAIGNS_NOTE,
  CLASIC_CAMPAIGNS,
  CLASIC_CAMPAIGNS_NOTE,
  SMART_CAMPAIGNS,
  ALL_CUSTOMERS,
  INACTIVE_CUSTOMERS,
  SPECIFIC_SEGMENTS,
} from 'constants/translations';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import FeatureAccess from 'common/lib/FeatureAccess';
import CampaignTypeRadioGroup from './CampaignTypeRadioGroup';
import { CAMPAIGN_TYPES, CAMPAIGN_OBJECT_KEYS, TAB_NAMES } from './constants';
import { fileControllerUtils } from './CampaignContacts/utils';

function CampaignTypeStep({
  handleChange,
  translate,
  isEdit = false,
  resetValuesOnTypeChange,
  remainingCampaign,
  setFieldValue,
}) {
  const { featureAccess } = useContext(featureAccessContext);
  const { selectedStore } = useContext(userContext);
  const { setFileController, setAudienceType } = useContext(customersContext);
  const location = useLocation();

  // reset values once landed ( contact uploader related fields )
  useEffect(() => {
    setAudienceType(TAB_NAMES.SEGMENTS); // reset tab to be segments
    setFieldValue(CAMPAIGN_OBJECT_KEYS.USE_CONTACT_IMPORT, false); // reset contact import to false
    setFileController(fileControllerUtils); // reset default value of file uploader
  }, []);

  return (
    <div className="pb-32 mt-6">
      <div className="bg-gray-100 p-4">
        <div>
          <Label title={<Text value={CAMPAIGN_TITLE} />}>
            <Field
              type="text"
              name={CAMPAIGN_OBJECT_KEYS.TITLE}
              disabled={isEdit}
              maxLength={50}
              placeholder={translate(EX_CHECK_OUR_OFFERS)}
              component={Input}
            />
          </Label>
        </div>
      </div>
      <div>
        <div className="bg-gray-100 p-4 mt-6">
          <div className="mb-4">
            <Text value={SMART_CAMPAIGNS} className="font-bold text-lg" />
            <Text value={SMART_CAMPAIGNS_NOTE} className="text-gray-600" />
          </div>
          <Field
            name={CAMPAIGN_OBJECT_KEYS.TYPE}
            items={[
              {
                title: RETENTION,
                value: CAMPAIGN_TYPES.RETENTION,
                description: RETENTION_DESCRIPTION,
                icon: fill => <RetentionCampaignIcon fill={fill} />,
                disable: false,
                label: INACTIVE_CUSTOMERS,
              },
              {
                title: SEGMENT_TARGETING,
                value: CAMPAIGN_TYPES.SEGMENT_TARGETING,
                description: SEGMENT_TARGETING_DESCRIPTION,
                icon: fill => <SegmentTargetingCampaignIcon fill={fill} />,
                upgradeable: featureAccess
                  .get(ACCESS_MAP_KEYS.FEATURES)
                  ?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id))?.SEGMENT_TARGETING
                  ?.isBlocked,
                disable: false,
                label: SPECIFIC_SEGMENTS,
              },
            ]}
            component={CampaignTypeRadioGroup}
            onChange={e => {
              handleChange(e);
              resetValuesOnTypeChange();
            }}
          />
        </div>

        <div className="bg-gray-100 p-4 mt-6">
          <div className="mb-4">
            <Text value={CLASIC_CAMPAIGNS} className="font-bold text-lg" />
            <Text value={CLASIC_CAMPAIGNS_NOTE} className="text-gray-600" />
          </div>
          <Field
            name={CAMPAIGN_OBJECT_KEYS.TYPE}
            items={[
              {
                title: ANNOUNCEMENT,
                value: CAMPAIGN_TYPES.ANNOUNCEMENT,
                description: ANNOUNCEMENT_DESCRIPTION,
                icon: fill => <BullhornIcon width="24" color={fill} />,
                upgradeable: featureAccess
                  .get(ACCESS_MAP_KEYS.FEATURES)
                  ?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id))?.SEGMENT_TARGETING
                  ?.isBlocked,
                disable: remainingCampaign && remainingCampaign <= 0,
                label: ALL_CUSTOMERS,
              },
            ]}
            component={CampaignTypeRadioGroup}
            onChange={e => {
              handleChange(e);
              resetValuesOnTypeChange();
            }}
            remainingCampaign={remainingCampaign}
          />
        </div>
      </div>
    </div>
  );
}

CampaignTypeStep.propTypes = {
  handleChange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  resetValuesOnTypeChange: PropTypes.func.isRequired,
  remainingCampaign: PropTypes.number,
  setFieldValue: PropTypes.func,
};

export default CampaignTypeStep;
