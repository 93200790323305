import React, { useContext, useState } from 'react';
import { useLocation } from '@reach/router';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { List, AutoSizer } from 'react-virtualized';
import cx from 'classnames';

import { parse } from 'query-string';
import { breadcrumbs } from 'constants/translations';
import { Tag } from 'components/kit';
import { useSelectedStore, useScrollBottom, useReplaceParams, useMobile } from 'hooks';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import EmptyState from 'components/common/EmptyState';
import { ReactComponent as SkeletonBars } from 'assets/skeleton-bars.svg';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { context as customersContext } from 'context/customers';
import { Spinner } from 'components/kit';
import Fallback from 'components/common/Fallback';
import profileSchemas from './profileSchemas';
import { ORDERS } from 'components/common/orders/Frame/schemas.js';
import * as translations from 'constants/translations';
import {
  SEGMENT_TITLE_MAP,
  CUSTOMER_PROFILE_ORDERS_SORT_BY,
  CUSTOMER_PROFILE_ORDERS_SORT_BY_ORDER,
} from 'constants/customers';
import SegmentLabel from '../common/components/SegmentLabel';
import { allOrders } from 'paths';
import DeliveryAddress from './DeliveryAddress';
import OrderItem from 'components/common/orders/Table/Order/OrderItem';
import { ReactComponent as Wallet } from 'assets/wallet.svg';

const Profile = ({ customerId }) => {
  const isMobile = useMobile();
  const storeId = useSelectedStore();
  const { selectedStore } = useContext(userContext);
  const { segments } = useContext(customersContext);
  const { translate, direction, lang } = useContext(localeContext);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const searchParams = parse(location.search);
  const replace = useReplaceParams();

  const { data: { customer } = {}, loading, error, refetch } = useQuery(profileSchemas.CUSTOMER, {
    variables: { storeId, customerId: parseInt(customerId) },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const ordersVariables = {
    storeId,
    branchId: '',
    phone: searchParams.phoneNumber ? searchParams.phoneNumber : '',
    sort: {
      order: CUSTOMER_PROFILE_ORDERS_SORT_BY_ORDER.DESCENDING,
      field: CUSTOMER_PROFILE_ORDERS_SORT_BY.CREATED_AT,
    },
    page: 1,
  };
  const {
    loading: ordersLoading = false,
    data: { orders } = {},
    fetchMore: ordersFetchMore,
    error: ordersError,
    refetch: ordersReftech,
  } = useQuery(ORDERS, {
    variables: ordersVariables,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  useScrollBottom(() => {
    if (ordersError) return;
    if (ordersLoading || orders.orders.length === orders.totalCount) return;

    const nextPage = page + 1;

    setPage(nextPage);

    ordersFetchMore({
      variables: { ...ordersVariables, page: nextPage },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          orders: {
            ...prev.orders,
            orders: [...prev.orders.orders, ...fetchMoreResult.orders.orders],
          },
        });
      },
    });
  });

  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => {
    const address = customer.addresses[index];
    return <DeliveryAddress deliveryAddress={address} key={address.address} style={style} height={isMobile && 200} />;
  };

  return (
    <Layout
      isScrollable={false}
      top={customer && <Breadcrumbs path={breadcrumbs.CUSTOMER_PROFILE(customer.userName)} />}
    >
      <div
        className="grid grid-cols-1 md:grid-cols-3 gap-5 md:h-screen md:pb-32"
        id="scroll-view"
        style={{ direction: direction }}
      >
        <div className={cx('flex flex-col md:col-span-2 sm:pb-4', !isMobile && 'overflow-y-auto')}>
          {loading ? (
            <div className="my-10 mb-6 px-4 text-center">
              <Spinner />
            </div>
          ) : error ? (
            <div className="my-10 mb-6 px-4 text-center">
              <Fallback refetch={() => refetch()} />
            </div>
          ) : (
            customer && (
              <>
                <div className="mb-4">
                  <h3 className="text-lg font-bold mb-4">{translate(translations.PERSONAL_INFORMATION)}</h3>
                  <div className="grid grid-cols-4 border border-gray-300 p-4 gap-2">
                    <div className="col-start-1 col-end-3 mb-4">
                      <p className="text-gray-700 mb-1">{translate(translations.CUSTOMER_NAME)}</p>
                      <p>{customer.userName}</p>
                    </div>
                    <div className="col-start-3 mb-4 flex gap-2 h-fit	">
                      <SegmentLabel
                        segmentId={
                          customer.segmentId && Object.keys(segments).includes(customer.segmentId.toString())
                            ? customer.segmentId.toString()
                            : SEGMENT_TITLE_MAP.NEW.toString()
                        }
                        lang={lang}
                      />
                      <Tag
                        icon={<Wallet />}
                        title={`${translate(selectedStore.currency)} ${parseFloat(customer.walletBalance).toFixed(2)} `}
                        labelCss="px-2 py-2 text-green-700"
                        baseCss="bg-green-50"
                        lang={lang}
                      />
                    </div>
                    <div className="col-start-1 col-end-3 mb-4">
                      <p className="text-gray-700 mb-1">{translate(translations.PHONE_NUMBER)}</p>
                      <p>&lrm;{customer.phoneNumber}</p>
                    </div>
                    <div className="col-start-3 mb-4">
                      <p className="text-gray-700 mb-1">{translate(translations.EMAIL)}</p>
                      <p>{customer.email ? customer.email : '-'}</p>
                    </div>
                    <div className="col-start-1 col-end-3">
                      <p className="text-gray-700 mb-1">{translate(translations.FIRST_ORDER)}</p>
                      <p>
                        {
                          customer.firstOrder ? moment(customer.firstOrder)
                          .locale(lang)
                          .format(lang === 'en' ? 'MMMM DD, YYYY' : 'DD MMM YYYY') : '-'
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h3 className="text-lg font-bold mb-4">{translate(translations.SALES_INSIGHTS)}</h3>
                  <div className="flex flex-row gap-6">
                    <div className="flex-grow border border-gray-300 py-4 px-6">
                      <p className="text-gray-700 pb-2">{translate(translations.TOTAL_SALES)}</p>
                      <p>
                        {customer.totalSales} {translate(selectedStore.currency)}
                      </p>
                    </div>
                    <div className="flex-grow border border-gray-300 py-4 px-6">
                      <p className="text-gray-700 pb-2">{translate(translations.SALES_TOTAL_ORDERS)}</p>
                      <p>{customer.totalOrders}</p>
                    </div>
                    <div className="flex-grow border border-gray-300 py-4 px-6">
                      <p className="text-gray-700 pb-2">{translate(translations.CUSTOMERS_TABLE_HEADERS.LAST_ORDER)}</p>
                      <p>
                        {
                          customer.lastOrder ? moment(customer.lastOrder)
                          .locale(lang)
                          .fromNow() : '-'
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
          <div>
            <h3 className="text-lg font-bold mb-4">{translate(translations.ORDER_HISTORY)}</h3>
            {/* Added direction ltr since OrderItem already adjusts itself to arabic with direction still set to ltr */}
            <div style={{ direction: 'ltr' }}>
              {ordersLoading && !orders && <Spinner />}
              {ordersError && (
                <div className="my-10 mb-6 px-4 text-center">
                  <Fallback refetch={ordersReftech} />
                </div>
              )}
              {orders?.orders.map((item, i) => (
                <a
                  key={i + item.number}
                  href={replace(allOrders) + `&search_by=number&search_value=${item.number}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OrderItem
                    key={i}
                    showOrderStatus={true}
                    showScheduled={false}
                    orderItemWidth="md:w-30p"
                    item={item}
                    textSize="xs"
                    showOrderingUser={false}
                  />
                </a>
              ))}
              {ordersLoading && orders?.orders.length > 0 && <Spinner />}
            </div>
          </div>
        </div>
        <div className={cx('flex flex-col sm:pb-16', !isMobile && 'overflow-y-auto overflow-x-hidden')}>
          {loading ? (
            <Spinner />
          ) : (
            customer && (
              <>
                <h3 className="text-lg font-bold mb-4">{translate(translations.DELIVERY_ADDRESSES)}</h3>
                {customer.addresses.length === 0 ? (
                  <EmptyState
                    skeletonComponent={<SkeletonBars className="w-full" />}
                    emptyStateText={translations.EMPTY_DELIVERY_ADDRESSES}
                  />
                ) : (
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        width={width}
                        height={isMobile ? customer.addresses.length * 225 : height}
                        rowCount={customer.addresses.length}
                        rowHeight={isMobile ? 210 : 215}
                        rowRenderer={rowRenderer}
                        scrollToAlignment={'end'}
                        className={isMobile ? 'overflow-hidden' : 'overflow-y-auto overflow-x-hidden' }
                      />
                    )}
                  </AutoSizer>
                )}
              </>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

Profile.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default Profile;
