import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const OrderDetailsGift = ({ order }) => {
  const { lang } = useContext(localeContext);
  const { userData, gift } = order || {};
  const { recipient } = userData || {};
  const { name, phoneNumber, giftNotes } = recipient || {};

  return (
    recipient &&
    gift && (
      <>
        <div className="mt-2">
          <span className="block text-sm font-semibold">
            <Text value={translations.RECIPIENT_INFORMATION} />
          </span>
        </div>
        <div className="flex w-full border-t border-b border-gray-300 py-4 mt-2 flex-col">
          <div className="w-full">
            <span className={cx('block text-sm', lang === 'ar' && 'text-right')}>{name}</span>
            <a
              href={`tel:${phoneNumber}`}
              className={cx(lang === 'ar' && 'text-right', 'block text-primary-base text-sm pt-1')}
            >
              {phoneNumber}
            </a>
          </div>
          {giftNotes && (
            <div className="w-full mt-2">
              <span className={cx('block text-xs text-gray-700', lang === 'ar' && 'text-right')}>
                <Text value={translations.GIFT_NOTE} />
              </span>
              <span className={cx('block text-sm', lang === 'ar' && 'text-right')}>{giftNotes}</span>
            </div>
          )}
        </div>
      </>
    )
  );
};

OrderDetailsGift.propTypes = {
  order: PropTypes.shape({
    gift: PropTypes.string,
    userData: PropTypes.shape({
      recipient: PropTypes.shape({
        name: PropTypes.string,
        phoneNumber: PropTypes.number,
        giftNotes: PropTypes.string,
      }),
    }),
  }),
};
export default OrderDetailsGift;
