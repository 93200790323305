import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { apiMutate } from 'service/utils/mutation';
import { assignDriverToOrderMutation } from '../../mutations/verd';

import { UpdateStoreDriverMutationInput } from '../../types/generated';

type useAssignDriverToOrderType = (variables: UpdateStoreDriverMutationInput) => any;

const useAssignDriverToOrder: useAssignDriverToOrderType = () => variables =>
  apiMutate({
    mutation: assignDriverToOrderMutation,
    variables,
    clientName: GRAPHQL_CLIENT_NAMES.VERD,
    requestHeaders: {},
    handler: (res: any) => res,
  });

export default useAssignDriverToOrder;
