import React, { useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const OrderDate = ({ date, timeZone }) => {
  const { lang } = useContext(localeContext);
  const direction = lang === 'ar' ? 'rtl' : 'ltr';

  return moment(date).isSame(new Date(), 'day') ? (
    // same day
    <span className="flex flex-col items-start inline-block" style={{ direction }}>
      <span className="text-sm text-gray-700">
        <Text value={translations.TODAY} />
      </span>
      <span className="text-sm">
        {moment(date)
          .tz(timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </span>
    </span>
  ) : moment(date).isSame(moment().subtract(1, 'days'), 'day') ? (
    // yesterday
    <span className="flex flex-col items-start inline-block" style={{ direction }}>
      <span className="text-sm text-gray-700">
        <Text value={translations.YESTERDAY} />
      </span>
      <span className="text-sm text-yellow-600">
        {moment(date)
          .tz(timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </span>
    </span>
  ) : (
    // other
    <span className="flex flex-col items-start inline-block" style={{ direction }}>
      <span className="text-sm text-gray-700">
        {moment(date)
          .tz(timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('DD MMM YYYY')}
      </span>
      <span className="text-sm text-red-600">
        {moment(date)
          .tz(timeZone)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('h:mm A')}
      </span>
    </span>
  );
};

OrderDate.propTypes = {
  date: PropTypes.string,
  timeZone: PropTypes.string,
};
export default OrderDate;
