import React, { useContext, useState, useRef, useEffect } from 'react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { ORDER_STATUS } from 'constants/order';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { SplitButton, Button } from 'components/kit';
import { ReactComponent as GiftReceiptIcon } from 'assets/gift-receipt.svg';
import { EXTERNAL_ORDER_IDENTIFIER_TYPE } from '../../constants';
import OrderInvoice from '../../../OrderInvoice';

const OrderDetailsPrint = ({ order, canBeSendToCourier, selected, isDeliveryBusiness, deliveryCourier, legalData }) => {
  const { externalOrderIdentifierLink, externalOrderIdentifierType } = deliveryCourier || {};
  const { gift, status, orderItems, paymentStatus } = order || {};
  const { lang } = useContext(localeContext);
  const mobileLargeMQ = useMediaQuery({ maxWidth: 425 });
  const mediumToLrgMQ = useMediaQuery({ minWidth: 426, maxWidth: 1024 });
  const lrgScreenMQ = useMediaQuery({ minWidth: 1025 });
  const componentRef = useRef();
  const [printAsGift, setPrintAsGift] = useState(false);
  const [printLang, setPrintLang] = useState('en');
  const [printSize, setPrintSize] = useState('A4');
  const pageStyleMediaQuery = `
  @media print {
  @page {
    size: ${printSize === 'Thermal' ? `3.15in ${10 + orderItems.length}in` : 'A4'};
    margin: 4%;
  }
}
`;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: () => pageStyleMediaQuery,
  });
  const handlePrintType = (isGift = true) => {
    setPrintAsGift(isGift);
    handlePrint();
  };
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (!isFirstRender.current) {
      setPrintAsGift(printAsGift);
      handlePrint();
    }
  }, [printAsGift]);
  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const externalOrderIdentifierTypeValue =
    externalOrderIdentifierType?.toLowerCase() === EXTERNAL_ORDER_IDENTIFIER_TYPE.QRCODE
      ? translations.OPEN_QRCODE
      : translations.PRINT_AWB;

  return (
    <div
      className={cx(
        'flex',
        lang === 'ar' && 'flex-row-reverse',
        !canBeSendToCourier && 'flex-col',
        !lrgScreenMQ && 'flex-wrap',
      )}
    >
      {paymentStatus !== ORDER_STATUS.WAITING_FOR_PAYMENT &&
        paymentStatus !== ORDER_STATUS.PAYMENT_FAILED &&
        paymentStatus !== ORDER_STATUS.REDIRECT_URL &&
        paymentStatus !== ORDER_STATUS.PAYMENT_EXPIRED && (
          <>
            <div
              className={cx(
                'flex pb-3 items-start xl:flex-1 flex-col',
                lang === 'ar' && 'flex-row-reverse',
                !canBeSendToCourier && 'border-b border-gray-300 mb-4',
                canBeSendToCourier
                  ? lrgScreenMQ
                    ? 'flex-col w-1/2' && (lang === 'ar' ? 'ml-2' : 'mr-2')
                    : 'flex-col w-full'
                  : lrgScreenMQ && 'w-full',
              )}
            >
              <span
                className={cx(
                  'text-sm font-semibold w-full pb-3',
                  !canBeSendToCourier && 'mb-3 border-b border-gray-300',
                )}
              >
                <Text value={translations.PRINT} />
              </span>
              <div
                className={cx(
                  'flex w-full px-2',
                  lang === 'ar' && 'flex-row-reverse',
                  canBeSendToCourier && 'flex-col',
                  !lrgScreenMQ && 'flex-wrap',
                )}
              >
                <div className={cx('flex w-full', !lrgScreenMQ && 'flex-wrap', lang === 'ar' && 'flex-row-reverse')}>
                  <div
                    className={cx(
                      'flex-1 flex flex-row h-10 px-0 md:mt-0',
                      lang === 'ar' ? 'sm:pl-4' : 'sm:pr-4',
                      mobileLargeMQ ? 'mt-0' : 'mt-4',
                    )}
                    style={{
                      minWidth: mediumToLrgMQ ? '10rem' : mobileLargeMQ ? '100%' : '0',
                    }}
                  >
                    <div className="w-full p-1 rounded bg-gray-300 flex cursor-pointer">
                      <span
                        role="presentation"
                        className={cx(
                          'flex items-center justify-center flex-1 rounded p-3',
                          printLang === 'en' && 'bg-white shadow',
                          canBeSendToCourier && 'text-xs',
                        )}
                        onClick={() => setPrintLang('en')}
                      >
                        English
                      </span>
                      <span
                        role="presentation"
                        className={cx(
                          'flex items-center justify-center flex-1 rounded p-3',
                          printLang === 'ar' && 'bg-white shadow',
                          canBeSendToCourier && 'text-xs',
                        )}
                        onClick={() => setPrintLang('ar')}
                      >
                        عربي
                      </span>
                    </div>
                  </div>
                  <div
                    className={cx(
                      'flex-1 flex flex-row h-10 px-0 mt-4 md:mt-0',
                      !canBeSendToCourier && (lang === 'ar' ? 'xl:pl-4' : 'xl:pr-4'),
                    )}
                    style={{
                      minWidth: mediumToLrgMQ ? '10rem' : mobileLargeMQ ? '100%' : '0',
                    }}
                  >
                    <div className="w-full p-1 rounded bg-gray-300 flex cursor-pointer">
                      <span
                        role="presentation"
                        className={cx(
                          'flex items-center justify-center flex-1 rounded p-3',
                          printSize === 'Thermal' && 'bg-white shadow',
                          canBeSendToCourier && 'text-xs',
                        )}
                        onClick={() => setPrintSize('Thermal')}
                      >
                        Thermal
                      </span>
                      <span
                        role="presentation"
                        className={cx(
                          'flex items-center justify-center flex-1 rounded p-3',
                          printSize === 'A4' && 'bg-white shadow',
                          canBeSendToCourier && 'text-xs',
                        )}
                        onClick={() => setPrintSize('A4')}
                      >
                        A4
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <div className={cx('flex-1 flex flex-row-reverse h-10 mt-4', !canBeSendToCourier && 'xl:mt-0')}>
                    {gift ? (
                      canBeSendToCourier || mobileLargeMQ || mediumToLrgMQ ? (
                        <>
                          <ReactToPrint
                            trigger={() => (
                              <Button weight="semibold" kind="quartary" size="base" icon={<GiftReceiptIcon />} full>
                                <Text full={false} value={translations.PRINT_GIFT_RECEIPT} />
                              </Button>
                            )}
                            onBeforeGetContent={() => setPrintAsGift(true)}
                            content={() => componentRef.current}
                            pageStyle={pageStyleMediaQuery}
                          />
                        </>
                      ) : (
                        <SplitButton
                          actionBtnTestId="print-gift-receipt"
                          dropBtnTestId="gift-split-arrow-btn"
                          type="button"
                          textColor="text-primary-base"
                          kind="quartary"
                          size="base"
                          icon={<GiftReceiptIcon />}
                          full
                          actionBtnText={translations.PRINT_GIFT_RECEIPT}
                          actionBtnOnClick={handlePrintType}
                          items={[
                            {
                              testId: 'print-receipt-only',
                              title: <Text value={translations.PRINT_RECEIPT} />,
                              onClick: () => {
                                handlePrintType(false);
                              },
                            },
                          ]}
                        />
                      )
                    ) : (
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            kind="quartary"
                            textColor="text-primary-base"
                            size="base"
                            icon="local_printshop"
                            weight="semibold"
                            full
                          >
                            <Text full={false} value={translations.PRINT_RECEIPT} />
                          </Button>
                        )}
                        content={() => componentRef.current}
                        pageStyle={pageStyleMediaQuery}
                      />
                    )}
                    <div style={{ display: 'none' }}>
                      <OrderInvoice
                        invoiceRef={componentRef}
                        legalData={legalData}
                        order={order}
                        store={selected}
                        lang={printLang}
                        isGift={printAsGift}
                        printSize={printSize}
                      />
                    </div>
                  </div>
                  {!!gift && (canBeSendToCourier || mobileLargeMQ || mediumToLrgMQ) && (
                    <div className={cx('flex-1 flex flex-row-reverse h-10 mt-4', !canBeSendToCourier && 'xl:mt-0')}>
                      <ReactToPrint
                        trigger={() => (
                          <Button
                            kind="quartary"
                            textColor="text-primary-base"
                            size="base"
                            icon="local_printshop"
                            weight="semibold"
                            full
                          >
                            <Text full={false} value={translations.PRINT_RECEIPT} />
                          </Button>
                        )}
                        onBeforeGetContent={() => setPrintAsGift(false)}
                        content={() => componentRef.current}
                        pageStyle={pageStyleMediaQuery}
                      />
                    </div>
                  )}
                </div>
              </div>
              {isDeliveryBusiness && externalOrderIdentifierLink && (
                <div className="flex w-full px-2 mt-2 flex-col">
                  {status !== ORDER_STATUS.CANCELED && (
                    <Button
                      href={externalOrderIdentifierLink}
                      download
                      isLink="true"
                      kind="quartary"
                      weight="semibold"
                      textColor="text-primary-base"
                      full
                    >
                      <Text full={false} value={externalOrderIdentifierTypeValue} />
                    </Button>
                  )}
                </div>
              )}
            </div>
          </>
        )}
    </div>
  );
};

OrderDetailsPrint.propTypes = {
  order: PropTypes.shape({
    gift: PropTypes.string,
    status: PropTypes.string,
    orderItems: PropTypes.shape({
      quantity: PropTypes.number,
      variant: PropTypes.shape({
        barCode: PropTypes.string,
        discountEnabled: PropTypes.bool.isRequired,
        discountedPrice: PropTypes.number,
        externalId: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        prepTime: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        propertySections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
        sku: PropTypes.string,
        titleAr: PropTypes.string.isRequired,
        titleEn: PropTypes.string.isRequired,
      }),
      paymentStatus: PropTypes.string,
    }),
  }),
  canBeSendToCourier: PropTypes.bool,
  selected: PropTypes.shape({
    timeZone: PropTypes.string,
    currency: PropTypes.shape({
      decimals: PropTypes.number.isRequired,
      titleAr: PropTypes.string.isRequired,
      titleEn: PropTypes.string.isRequired,
    }),
  }),
  isDeliveryBusiness: PropTypes.bool,
  deliveryCourier: PropTypes.shape({
    driverAssigned: PropTypes.bool.isRequired,
    driverName: PropTypes.string,
    driverPhoneNumber: PropTypes.number,
    referenceId: PropTypes.string,
    externalOrderIdentifierLink: PropTypes.string,
    externalOrderIdentifierType: PropTypes.string,
    trackingLink: PropTypes.string,
    hasDriverInfo: PropTypes.bool,
  }),
  legalData: PropTypes.shape({
    companyNameEn: PropTypes.string,
    companyNameAr: PropTypes.string,
    vatIdNumber: PropTypes.string,
  }),
};
export default OrderDetailsPrint;
