import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button, DropDown } from 'components/kit';
import {
  FILTER,
  PAYMENT_ORDER,
  PAYMENT_TYPE_ORDER,
  PAYMENT_NOT_AVAILABLE,
  DATE,
  DATE_RANGE,
  AREA,
  AREAS_NOT_AVAILABLE,
  APPLY_FILTERS,
  ORDER_TYPE,
  ZONE,
  NO_DELIVERY_ZONE,
  DRIVER_NAME,
} from 'constants/translations';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import { ReactComponent as FilterIcon } from 'assets/filter-icon.svg';
import { CheckboxGroup, RadioList } from 'components/form/elements';
import { getRange, stringifyRange } from 'utils/date';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { prepareAreasFilter, cleanPaymentMethods } from './utils';
import { deliveryTypes } from './data';

const OrderFrameDesktopFilters = ({
  filtersModalRef,
  filtersOpened,
  lang,
  setFiltersOpened,
  paymentMethods,
  filters,
  setFilters,
  dateOptions,
  areasFilterData,
  setQuery,
  selectedStore,
  query,
  driversList,
}) => {
  const { settings } = useContext(userContext);
  const dropDownRenderValues = () => {
    // checking if store use new delivery zone system (useDeliveryzonesMs)
    // NOTE later we will migrate all stores to new system
    if (!settings.useDeliveryzonesMs) {
      return {
        dropdownLabel: AREA,
        dropdownGroupTitle: AREA,
        dropdownPlaceHolder: AREAS_NOT_AVAILABLE,
        dropdownValue: filters.areas,
        dropdownOnChangeHandler: 'areas',
      };
    }
    return {
      dropdownLabel: ZONE,
      dropdownGroupTitle: ZONE,
      dropdownPlaceHolder: NO_DELIVERY_ZONE,
      dropdownValue: filters.delivery_zone_in,
      dropdownOnChangeHandler: 'delivery_zone_in',
    };
  };
  return (
    <div
      ref={filtersModalRef}
      className={cx('flex relative', lang === 'ar' && 'flex-row-reverse', lang === 'ar' ? 'ml-4' : 'mr-4')}
    >
      <div>
        <Button
          hFull
          icon={<FilterIcon />}
          onClick={() => {
            setFiltersOpened(!filtersOpened);
          }}
        >
          <Text value={FILTER} />
        </Button>
      </div>
      <div
        className={cx(
          'flex flex-wrap absolute rounded bg-white z-50 w-auto h-40',
          filtersOpened ? 'visible' : 'hidden',
          lang === 'ar' && 'flex-row-reverse',
        )}
        // TODO: convert to tailwind classes.
        style={{
          width: 'max-content',
          height: 'fit-content',
          maxWidth: '85vw',
          marginTop: '52px',
          padding: '16px',
          borderRadius: '4px',
          boxShadow: '0 0 10px 0 rgba(216, 218, 229, 0.6)',
          backgroundColor: '#ffffff',
        }}
      >
        <div className={cx('flex', lang === 'ar' ? 'ml-2' : 'mr-2')}>
          <DropDown
            float="left"
            position={lang === 'ar' ? 'right' : 'left'}
            label={<Text value={PAYMENT_ORDER} />}
            labelClassNames="text-black"
            icon="keyboard_arrow_down"
            testId_openDropDown="search-payment-type"
            testId_selectedData="default-payment-type"
            testId_listData="payment-list"
            noCloseOnSelect
          >
            <CheckboxGroup
              groupTitle={<Text value={PAYMENT_TYPE_ORDER} className="text-sm text-gray-700" />}
              placeholder={<Text value={PAYMENT_NOT_AVAILABLE} />}
              items={paymentMethods}
              value={filters.payment_methods}
              onChange={chosenPayments => {
                const cleanPayments = cleanPaymentMethods(chosenPayments.target.value);
                setFilters({
                  ...filters,
                  payment_methods: cleanPayments,
                });
              }}
              containerStyle={{
                marginBottom: '1.25rem',
                minWidth: '18rem',
              }}
            />
          </DropDown>
        </div>
        <div className={cx('flex', lang === 'ar' ? 'ml-2' : 'mr-2')}>
          <DropDown
            float="left"
            position={lang === 'ar' ? 'right' : 'left'}
            label={<Text value={DATE} />}
            labelClassNames="text-black"
            icon="keyboard_arrow_down"
            testId_openDropDown="search-date-range"
            testId_selectedData="default-date-range"
            testId_listData="date-range"
            noOverflow
            scrollable
            noCloseOnSelect
          >
            <RadioList
              groupTitle={<Text value={DATE_RANGE} className="text-sm text-gray-700 px-4 pb-1 pt-3" />}
              items={dateOptions}
              value={query.date}
              onChange={option => {
                if (option.target.value !== 'custom') {
                  setFilters({
                    ...filters,
                    date: option.target.value,
                    range: stringifyRange(getRange(option.target.value, selectedStore.createdAt)),
                  });
                }
              }}
              bodyElementClassName="px-4 py-2"
              elementClassName="px-3 py-1"
              elementStyle={{
                direction: lang === 'ar' ? 'rtl' : 'unset',
              }}
              containerStyle={{
                marginBottom: '1.25rem',
                minWidth: '18rem',
              }}
              hoverHighlight
            />
          </DropDown>
        </div>
        <div className={cx('flex', lang === 'ar' ? 'ml-2' : 'mr-2')}>
          <DropDown
            float="left"
            position={lang === 'ar' ? 'right' : 'left'}
            label={<Text value={dropDownRenderValues().dropdownLabel} />}
            labelClassNames="text-black"
            icon="keyboard_arrow_down"
            testId_openDropDown="search-areas"
            testId_selectedData="default-area"
            testId_listData="areas-list"
            noCloseOnSelect
          >
            <CheckboxGroup
              groupTitle={<Text value={dropDownRenderValues().dropdownGroupTitle} className="text-sm text-gray-700" />}
              placeholder={<Text value={dropDownRenderValues().placeholder} />}
              items={prepareAreasFilter(areasFilterData, lang)}
              value={dropDownRenderValues().dropdownValue}
              onChange={chosenAreas => {
                setFilters({
                  ...filters,
                  [dropDownRenderValues().dropdownOnChangeHandler]: chosenAreas.target.value,
                });
              }}
              containerStyle={{
                marginBottom: '1.25rem',
                minWidth: '18rem',
                maxHeight: '18rem',
              }}
            />
          </DropDown>
        </div>
        <div className={cx('flex', lang === 'ar' ? 'ml-2' : 'mr-2')}>
          <DropDown
            float="left"
            position={lang === 'ar' ? 'right' : 'left'}
            label={<Text value={ORDER_TYPE} />}
            labelClassNames="text-black"
            icon="keyboard_arrow_down"
            testId_openDropDown="search-order-type"
            testId_selectedData="default-order-type"
            testId_listData="order-types-list"
            noCloseOnSelect
          >
            <CheckboxGroup
              groupTitle={<Text value={ORDER_TYPE} className="text-sm text-gray-700" />}
              items={deliveryTypes}
              value={filters.delivery_type}
              onChange={chosenTypes => {
                const isManualOrder = chosenTypes.target.value.includes(DELIVERY_BUSY_MODE.MANUAL);
                setFilters({
                  ...filters,
                  delivery_type: chosenTypes.target.value,
                  is_manual_order: isManualOrder,
                });
              }}
              containerStyle={{
                marginBottom: '1.25rem',
                minWidth: '18rem',
              }}
            />
          </DropDown>
        </div>
        {driversList?.length > 0 && (
          <div className={cx('flex', lang === 'ar' ? 'ml-6' : 'mr-6')}>
            <DropDown
              float="left"
              position={lang === 'ar' ? 'right' : 'left'}
              label={<Text value={DRIVER_NAME} />}
              labelClassNames="text-black"
              icon="keyboard_arrow_down"
              testId_openDropDown="driver-id-type"
              testId_selectedData="driver-id-type"
              testId_listData="driver-id-list"
            >
              <RadioList
                groupTitle={<Text value={DRIVER_NAME} className="text-sm text-gray-700 px-4 pb-1 pt-3" />}
                items={driversList || []}
                value={filters.driver_id}
                onChange={option =>
                  setFilters({
                    ...filters,
                    driver_id: option.target.value,
                  })
                }
                bodyElementClassName="px-4 py-2"
                elementClassName="px-3 py-1"
                elementStyle={{
                  direction: lang === 'ar' ? 'rtl' : 'unset',
                }}
                containerStyle={{
                  marginBottom: '1.25rem',
                  minWidth: '18rem',
                }}
                hoverHighlight
              />
            </DropDown>
          </div>
        )}
        <div>
          <Button
            onClick={() => {
              setQuery(filters);
              setFiltersOpened(!filtersOpened);
            }}
            kind="primary"
            size="base"
            style={{
              paddingLeft: '2rem',
              paddingRight: '2rem',
            }}
          >
            <Text value={APPLY_FILTERS} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderFrameDesktopFilters;

OrderFrameDesktopFilters.propTypes = {
  filtersOpened: PropTypes.bool.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  setFiltersOpened: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  driversList: { id: PropTypes.string.isRequired, title: PropTypes.string.isRequired },
};
