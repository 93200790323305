import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';
import PaidThrough from '../PaidThrough';

const OrderDetailsPayments = ({
  paidThrough,
  lang,
  total,
  paidByWallet = 0,
  paidByCreditCard = 0,
  currency,
  direction,
}) => (
  <>
    <div className={cx('flex flex-flex w-full mt-3', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
      <span className="text-xs font-semibold text-black">
        <Text value={translations.PAYMENT_METHODS} />
      </span>
    </div>
    {paidThrough === PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD ? (
      <div className="mt-3 pb-2 border-b-4 border-gray-300">
        <div className={cx('flex flex-flex w-full ', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
          <span className="text-xs flex items-center">
            <Text value={translations.CREDIT_CARD} />
          </span>
          <span style={{ direction }} className="text-xs">
            <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
            {paidByCreditCard.toFixed(currency.decimals)}
          </span>
        </div>
        <div className={cx('flex flex-flex w-full py-2', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
          <span className="text-xs flex items-center">
            <Text value={translations.ORDERFAST_WALLET} />
          </span>
          <span style={{ direction }} className="text-xs">
            <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
            {paidByWallet.toFixed(currency.decimals)}
          </span>
        </div>
      </div>
    ) : (
      <div
        className={cx(
          'flex flex-flex w-full mt-3 pb-4 border-b-4 border-gray-300',
          lang === 'ar' && 'flex-row-reverse',
          'justify-between',
        )}
      >
        <span className="text-xs flex items-center">
          <PaidThrough paidThrough={paidThrough} />
        </span>
        <span style={{ direction }} className="text-xs">
          <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
          {total.toFixed(currency.decimals)}
        </span>
      </div>
    )}
  </>
);

OrderDetailsPayments.propTypes = {
  paidThrough: PropTypes.string.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  total: PropTypes.number.isRequired,
  paidByWallet: PropTypes.number,
  paidByCreditCard: PropTypes.number,
};

export default OrderDetailsPayments;
