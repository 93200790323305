import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/kit';
import { ORDER_STATUS } from 'constants/order';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

export const MerchantDeliveryActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  deliveryType,
}) => {
  const { status: orderStatus } = order || {};
  const showNextStatus = () => {
    switch (orderStatus) {
      case ORDER_STATUS.SUBMITTED:
        return (
          <Button
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false })}
            kind="primary"
            size="base"
            data-testid="accept-order-button"
          >
            <Text value={translations.ACCEPT_ORDER} />
          </Button>
        );
      case ORDER_STATUS.ACCEPTED:
        return (
          <Button
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.READY, sendCourierId: false });
            }}
            kind="primary"
            size="base"
            data-testid="ready-order-button"
          >
            <Text value={translations.READY_ORDER} />
          </Button>
        );
      case ORDER_STATUS.READY:
        if (deliveryType === DELIVERY_BUSY_MODE.PICKUP || deliveryType === DELIVERY_BUSY_MODE.BEACH) {
          return (
            <Button
              isSpinning={isUpdatingStatus}
              disabled={shouldDisableActionBtns}
              onClick={() => {
                sendChangeStatus({ status: ORDER_STATUS.FULFILLED, sendCourierId: false });
              }}
              kind="primary"
              size="base"
              data-testid="fulfill-order-button"
            >
              <Text value={translations.FULFILL_ORDER} />
            </Button>
          );
        }
        return (
          <Button
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DISPATCHED, sendCourierId: false });
            }}
            kind="primary"
            size="base"
            data-testid="dispatch-order-button"
          >
            <Text value={translations.DISPATCH_ORDER} />
          </Button>
        );

      case ORDER_STATUS.DISPATCHED:
        return (
          <Button
            isSpinning={isUpdatingStatus}
            disabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DELIVERED, sendCourierId: false });
            }}
            kind="primary"
            size="base"
            data-testid="deliver-order-button"
          >
            <Text value={translations.DELIVER_ORDER} />
          </Button>
        );
      case ORDER_STATUS.DELIVERED:
      default:
        return null;
    }
  };
  return showNextStatus();
};

MerchantDeliveryActionButtons.propTypes = {
  order: PropTypes.shape({ status: PropTypes.string.isRequired }),
  isUpdatingStatus: PropTypes.bool.isRequired,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
  openOrderDeliveryConfirmationPopUp: PropTypes.func.isRequired,
  isExpectedAtValid: PropTypes.bool.isRequired,
};
export default MerchantDeliveryActionButtons;
