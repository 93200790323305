import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Field, Text } from 'components/service';
import { Toggle, Add } from 'components/kit';
import { Label, Section, Row } from 'components/form/generic';
import { Input } from 'components/form/elements';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const BasicDetails = ({ values }) => {
  const { lang } = useContext(localeContext);

  return (
    <Section bottomOffset={4} title={<Text value={translations.BASIC_DETAILS} />}>
      <Text value={translations.BUSINESS_LOCATIONS_NAME} className="text-base -mb-4" />
      <Row className="flex-col md:flex-row">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
            <Label title={<Text value={translations.ENGLISH} />}>
              <Field
                type="text"
                name="titleEn"
                placeholder={translations.ENTER_BUSINESS_LOCATION_NAME[0]}
                component={Input}
                data-testid="business-location-name-en"
              />
            </Label>
          </div>
          <div className="w-full mb-0 md:mb-0">
            <Label title={<Text value={translations.ARABIC} />}>
              <Field
                type="text"
                direction="rtl"
                name="titleAr"
                placeholder={translations.ENTER_BUSINESS_LOCATION_NAME[1]}
                component={Input}
                data-testid="business-location-name-ar"
              />
            </Label>
          </div>
        </div>
      </Row>
      <Row>
        <Label title={<Text value={translations.CUSTOMER_SERVICE_NUMBER} />}>
          <Field
            type="text"
            name="contactNumber"
            placeholder={
              lang === 'en' ? translations.ENTER_CUSTUMER_SERVIVE_NUM[0] : translations.ENTER_CUSTUMER_SERVIVE_NUM[1]
            }
            component={Input}
          />
        </Label>
      </Row>
      <Toggle
        isvisible={values.addressEn || values.addressAr}
        link={<Add title={<Text value={translations.ADDRESS} />} />}
        content={
          <>
            <Text value={translations.ADDRESS} className="text-base mb-2" />
            <Row className="flex-col md:flex-row">
              <div className="flex flex-col md:flex-row justify-between items-start">
                <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
                  <Label title={<Text value={translations.ENGLISH} />}>
                    <Field
                      type="text"
                      name="addressEn"
                      placeholder={translations.ENTER_ADDRESS[0]}
                      component={Input}
                      data-testid="business-location-name-en"
                    />
                  </Label>
                </div>
                <div className="w-full mb-0 md:mb-0">
                  <Label title={<Text value={translations.ARABIC} />}>
                    <Field
                      type="text"
                      direction="rtl"
                      name="addressAr"
                      placeholder={translations.ENTER_ADDRESS[1]}
                      component={Input}
                      data-testid="business-location-address-ar"
                    />
                  </Label>
                </div>
              </div>
            </Row>
          </>
        }
      />
    </Section>
  );
};

BasicDetails.propTypes = {
  values: PropTypes.shape({
    addressAr: PropTypes.string.isRequired,
    addressEn: PropTypes.string.isRequired,
    contactNumber: PropTypes.string.isRequired,
    deliveryOrdersEnabled: PropTypes.bool.isRequired,
    dineIn: PropTypes.bool.isRequired,
    externalId: PropTypes.string.isRequired,
    forceScheduledThreshold: PropTypes.number.isRequired,
    isPOS: PropTypes.bool.isRequired,
    location: PropTypes.objectOf(
      PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
    ),
    openingHours: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.shape({
          closeAt: PropTypes.string.isRequired,
          day: PropTypes.string.isRequired,
          offDay: PropTypes.bool.isRequired,
          openAt: PropTypes.string.isRequired,
        }),
      ),
    ),
    pickupOrdersEnabled: PropTypes.bool.isRequired,
    pickupOrdersType: PropTypes.string.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }).isRequired,
};

export default BasicDetails;
