import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';

import { Wizard } from 'components/kit';
import {
  DELIVERECT_MODAL_STEP_1_TITLE,
  DELIVERECT_MODAL_STEP_2_TITLE,
  DELIVERECT_MODAL_RELOAD,
  LEARN_MORE,
} from 'constants/translations';
import { TAX_INCLUSIVE } from 'constants/integrations';
import { context as localeContext } from 'context/locale';
import { BUTTON, URL } from 'components/kit/Wizard/constants';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import { context } from './context';

import { UPDATE_DELIVERECT_CONFIGURATION } from './schemas';

const Deliverect = ({ courier, onCancel, changeSettings, closeable, defaultStep }) => {
  const {
    integrationData: { courierIntegrationInfo },
  } = courier;
  const { storeId, initialValues, handleRefresh, taxType } = useContext(context);
  const { lang } = useContext(localeContext);

  const additionalActions = [
    {
      type: URL,
      href:
        lang === 'en'
          ? 'https://help.zyda.com/en/articles/6340704-integrating-with-deliverect'
          : 'https://help.zyda.com/ar/articles/6340704-%D8%A7%D9%84%D8%AF%D9%85%D8%AC-%D9%85%D8%B9-%D8%AF%D9%84%D9%8A%D9%81%D9%8A%D8%B1%D9%83%D8%AA',
      title: LEARN_MORE,
    },
    {
      type: BUTTON,
      onClick: handleRefresh,
      title: DELIVERECT_MODAL_RELOAD,
    },
  ];

  const steps = [
    {
      title: DELIVERECT_MODAL_STEP_1_TITLE,
      Component: () => <FirstStep courierIntegrationInfo={courierIntegrationInfo} />,
    },
    {
      title: DELIVERECT_MODAL_STEP_2_TITLE,
      Component: () => <SecondStep />,
    },
  ];

  const [updateDeliverectConfiguration] = useMutation(UPDATE_DELIVERECT_CONFIGURATION, {
    variables: { storeId, isTaxInclusive: taxType === TAX_INCLUSIVE },
    onCompleted: () => {
      changeSettings();
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => {
        closeable(true);
        updateDeliverectConfiguration();
        onCancel();
      }}
    >
      {({ errors, setFieldValue, setFieldError, validateForm, submitForm, setFieldTouched }) => (
        <Wizard isModal additionalActions={additionalActions} defaultStep={defaultStep}>
          {steps.map((step, i) => (
            <step.Component
              title={step.title}
              key={step.title}
              stepKey={i}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              errors={errors}
              validateForm={validateForm}
              submitForm={submitForm}
            />
          ))}
        </Wizard>
      )}
    </Formik>
  );
};

Deliverect.propTypes = {
  courier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    authenticationType: PropTypes.string.isRequired,
    integrationData: PropTypes.shape({
      courierBasicInfo: PropTypes.shape({
        title: PropTypes.shape({
          en: PropTypes.string.isRequired,
          ar: PropTypes.string.isRequired,
        }).isRequired,
        description: PropTypes.shape({
          en: PropTypes.string.isRequired,
          ar: PropTypes.string.isRequired,
        }).isRequired,
      }),
      integrationsConstraints: PropTypes.arrayOf(PropTypes.string),
      csOnly: PropTypes.bool,
      courierIntegrationInfo: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          body: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
        }),
      ),
      learnMore: PropTypes.shape({
        ar: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
      }),
      courierDynamicSection: PropTypes.arrayOf(
        PropTypes.shape({
          body: PropTypes.shape({
            template: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  changeSettings: PropTypes.func.isRequired,
  closeable: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultStep: PropTypes.number.isRequired,
};

export default Deliverect;
