import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ORDER_STATUS } from 'constants/order';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const StatusOrder = ({ status, lang }) => {
  switch (status) {
    case ORDER_STATUS.ACCEPTED:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-primary-base" />
          <div>
            <Text
              value={translations.ACCEPTED_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-primary-base')}
            />
          </div>
        </div>
      );
    case ORDER_STATUS.CANCELED:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-zyda-red-500" />
          <div>
            <Text
              value={translations.CANCELED_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-red-600')}
            />
          </div>
        </div>
      );
    case ORDER_STATUS.DELIVERED:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-zyda-green-500" />
          <div>
            <Text
              value={translations.COMPLETED_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-green-600')}
            />
          </div>
        </div>
      );
    case ORDER_STATUS.FULFILLED:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-zyda-green-500" />
          <div>
            <Text
              value={translations.COMPLETED_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-green-600')}
            />
          </div>
        </div>
      );
    case ORDER_STATUS.DISPATCHED:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-primary-base" />
          <div>
            <Text
              value={translations.DISPATCHED_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-primary-base')}
            />
          </div>
        </div>
      );
    case ORDER_STATUS.SUBMITTED:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-zyda-yellow-300" />
          <div>
            <Text
              value={translations.PENDING_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-yellow-600')}
            />
          </div>
        </div>
      );

    case ORDER_STATUS.READY:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-primary-base" />
          <div>
            <Text
              value={translations.READY_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-primary-base')}
            />
          </div>
        </div>
      );
    default:
      return '';
  }
};

StatusOrder.propTypes = {
  lang: PropTypes.string,
  status: PropTypes.oneOf([
    ORDER_STATUS.READY,
    ORDER_STATUS.DISPATCHED,
    ORDER_STATUS.DELIVERED,
    ORDER_STATUS.ACCEPTED,
    ORDER_STATUS.CANCELED,
    ORDER_STATUS.FULFILLED,
    ORDER_STATUS.SUBMITTED,
  ]),
};
export default StatusOrder;
