import React from 'react';
import * as yup from 'yup';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

const zoneFormValidationSchema = yup.object().shape({
  zoneName: yup.string().required(<Text value={translations.ZONE_NAME_REQ} />),
  deliveryFee: yup
    .number()
    .required(<Text value={translations.DELIVERY_FEES_REQ} />)
    .min(0, <Text value={translations.GREATER_THAN_OR_EQUAL_ZERO} />)
    .typeError(<Text value={translations.NUMBERS_ONLY} />),
  minValue: yup
    .number()
    .integer(<Text value={translations.MIN_ORDER_INTEGER} />)
    .min(0, <Text value={translations.GREATER_THAN_OR_EQUAL_ZERO} />)
    .typeError(<Text value={translations.NUMBERS_ONLY} />),
  deliveryTime: yup
    .number()
    .required(<Text value={translations.DELIVERY_TIME_REQ} />)
    .integer(<Text value={translations.DELIVERY_TIME_INTEGER} />)
    .min(1, <Text value={translations.GREATER_THAN_ZERO} />)
    .typeError(<Text value={translations.NUMBERS_ONLY} />),
  posExternalId: yup.string().when('needsPOSExternalId', {
    is: true,
    then: yup.string().required(<Text value={translations.EXTERNAL_ID_IS_REQUIRED} />),
  }),
});

export default zoneFormValidationSchema;
