import React from 'react';
import { Field, getIn } from 'formik';

export default ({ name, submitOnEnter, component: Component, stopValidateFunctionPropagation = false, ...props }) => (
  <Field
    name={name}
    validate={props.validate}
    render={({ field, form: { errors, touched, submitCount } }) => {
      const error = getIn(errors, field.name);
      const isTouched = !!getIn(touched, field.name);
      const isFormSubmitted = submitCount > 0;
      const { validate, ...restOfProps } = props;
      return (
        <Component
          onKeyPress={e => {
            e.key === 'Enter' && Component.displayName !== 'Textarea' && !submitOnEnter && e.preventDefault();
          }}
          {...field}
          {...(!stopValidateFunctionPropagation && validate)}
          {...restOfProps}
          error={isTouched || isFormSubmitted ? error : ''}
        />
      );
    }}
  />
);
