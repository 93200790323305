import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { ReactComponent as Copy } from 'assets/copy.svg';
import * as translations from 'constants/translations';
import { copyToClipboard } from '../helpers';

const OrderDetailsUserContact = ({ order }) => {
  const [phoneCopied, setPhoneCopied] = useState(false);
  const { lang } = useContext(localeContext);
  const { userData } = order || {};
  const { address, phoneNumber, email } = userData || {};

  useEffect(() => {
    phoneCopied && setTimeout(() => setPhoneCopied(false), 3000);
  }, [phoneCopied]);

  return (
    <>
      <div className={cx('flex flex-row justify-between', lang === 'ar' && 'flex-row-reverse', address && 'mt-2')}>
        <span className="block text-sm font-semibold">
          <Text value={translations.CONTACT_INFORMATION} />
        </span>
        <span
          role="presentation"
          className={cx('relative cursor-pointer text-primary-base', lang === 'ar' ? 'text-left' : 'text-right')}
          onClick={() => {
            copyToClipboard(phoneNumber);
            setPhoneCopied(true);
          }}
        >
          <Copy className="inline fill-current text-primary-base" />
          <span className="text-primary-base">
            {phoneCopied ? (
              <Text value={translations.NUMBER_COPIED} className="inline" />
            ) : (
              <Text value={translations.COPY_NUMBER} className="inline" />
            )}
          </span>
        </span>
      </div>
      <div className="w-full border-t border-b border-gray-300 py-4 mt-2">
        <a href={`tel:${phoneNumber}`} className={cx(lang === 'ar' && 'text-right', 'block text-primary-base text-sm')}>
          {phoneNumber}
        </a>
        <span className={cx('block text-sm py-1', lang === 'ar' && 'text-right')}>
          {email || <Text value={translations.NO_EMAIL_PROVIDED} />}
        </span>
      </div>
    </>
  );
};

OrderDetailsUserContact.propTypes = {
  order: PropTypes.shape({
    userData: PropTypes.shape({
      address: PropTypes.shape({
        area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
      }),
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
};
export default OrderDetailsUserContact;
