import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ORDER_STATUS } from 'constants/order';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { TIMELINE_ENTITY_TYPES_ENUMS } from '../constants';

const OrderStatus = ({ status, entityType, assignee, assigneeAr, partnerError }) => {
  const { lang } = useContext(localeContext);
  const isArabic = lang === 'ar';

  const entityTypeText =
    entityType === TIMELINE_ENTITY_TYPES_ENUMS.ORDER_TYPE ? (
      <Text value={translations.TIMELINE_ORDER_TYPE} className={cx(isArabic ? 'ml-1' : 'mr-1')} full={false} />
    ) : (
      <Text value={translations.TIMELINE_DELIVERY_TYPE} className={cx(isArabic ? 'ml-1' : 'mr-1')} full={false} />
    );
  const getOrderStatusText = orderStatus => (
    <span className="flex">
      {entityTypeText}
      <Text value={orderStatus} className={cx(isArabic && 'ml-1')} full={false} />
      {partnerError && <span>: {partnerError}</span>}
    </span>
  );

  const getAssignedOrderText = orderStatus => (
    <span className="flex">
      {isArabic && assigneeAr}
      <Text value={orderStatus} full={false} className={cx(isArabic ? 'ml-1' : 'mr-1')} />
      {!isArabic && assignee}
    </span>
  );

  switch (status) {
    case ORDER_STATUS.ACCEPTED:
      return getOrderStatusText(translations.ACCEPTED_ORDERS);
    case ORDER_STATUS.ASSIGNED:
      return getAssignedOrderText(translations.ASSIGNED_TO);
    case ORDER_STATUS.CANCELED:
      return getOrderStatusText(translations.CANCELED_ORDERS);
    case ORDER_STATUS.DECLINED:
      return getOrderStatusText(translations.DECLINED_ORDERS);
    case ORDER_STATUS.DELIVERED:
      return getOrderStatusText(translations.DELIVERED_ORDERS);
    case ORDER_STATUS.PUSHED:
      return getOrderStatusText(translations.PUSHED_ORDERS);
    case ORDER_STATUS.PUSH_FAILED:
      return getOrderStatusText(translations.PUSH_FAILED_ORDERS);
    case ORDER_STATUS.DISPATCHED:
      return getOrderStatusText(translations.DISPATCHED_ORDERS);
    case ORDER_STATUS.SUBMITTED:
      return getOrderStatusText(translations.SUBMITTED_ORDERS);
    case ORDER_STATUS.READY:
      return getOrderStatusText(translations.READY_ORDERS);
    case ORDER_STATUS.FULFILLED:
      return getOrderStatusText(translations.FULFILLED_ORDERS);
    case ORDER_STATUS.MANUALLY_ORDERED:
      return getOrderStatusText(translations.MANUALLY_ORDERED);
    default:
      return '';
  }
};

OrderStatus.propTypes = {
  status: PropTypes.oneOf([
    ORDER_STATUS.ACCEPTED,
    ORDER_STATUS.CANCELED,
    ORDER_STATUS.DELIVERED,
    ORDER_STATUS.DISPATCHED,
    ORDER_STATUS.SUBMITTED,
    ORDER_STATUS.READY,
    ORDER_STATUS.FULFILLED,
    ORDER_STATUS.ASSIGNED,
    ORDER_STATUS.DECLINED,
  ]),
  entityType: PropTypes.oneOf([
    TIMELINE_ENTITY_TYPES_ENUMS.ORDER_TYPE,
    TIMELINE_ENTITY_TYPES_ENUMS.DELIVERY_REQUEST_TYPE,
  ]),
  assignee: PropTypes.string,
  assigneeAr: PropTypes.string,
  partnerError: PropTypes.string,
};

export default OrderStatus;
