import React, { useContext, useState, useEffect } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/client';

import { context as localeContext } from 'context/locale';
import { Row, Label } from 'components/form/generic';
import { Input } from 'components/form/elements';
import { Text, Field, Link } from 'components/service';
import * as translations from 'constants/translations';
import * as paths from 'paths.js';
import { copyToClipboard } from '../../../../../../utils';
import { INITIATE_DELIVERECT_BRANCHES_LINKING } from '../../schemas';
import { context } from '../../context';
import Taxes from './Taxes';

const SecondStep = () => {
  const [copied, setCopied] = useState('');
  const { lang } = useContext(localeContext);
  const { storeId, fields, branches } = useContext(context);

  const [initiateDeliverectBranchesLinking] = useMutation(INITIATE_DELIVERECT_BRANCHES_LINKING, {
    variables: { storeId, branches },
  });

  useEffect(() => {
    initiateDeliverectBranchesLinking();
  }, []);

  return (
    <div>
      <>
        <Text className="mb-4 whitespace-pre-wrap" value={translations.DELIVERECT_MODAL_STEP_2_DESCRIPTION} />
        <Text className="font-bold mb-3" value={translations.DELIVERECT_MODAL_STEP_2_NOTE} />
        <a target="_blank" href="https://frontend.deliverect.com" rel="noreferrer">
          <Text className="text-primary-base underline mb-4" value={translations.DELIVERECT_MODAL_LINK} />
        </a>
      </>

      {!fields.length && (
        <div className="flex justify-between p-4 mb-4 border">
          <Text full={false} value={translations.DELIVERECT_MODAL_NO_BRANCHES} />
          <Link to={paths.branchCreation} target="_blank">
            <Text className="mx-1 text-primary-base underline" value={translations.DELIVERECT_MODAL_ADD_BRANCH} />
          </Link>
        </div>
      )}

      {fields?.map(field => {
        const validation = value => {
          let error;
          if (field?.required && !value) error = translations.REQUIRED[lang === 'en' ? 0 : 1];
          return error;
        };
        return (
          <Row className="w-full" key={field.label?.en}>
            <div className="border-gray-300 p-4 border rounded mb-4">
              <div className="flex flex-row w-full">
                <div className="flex flex-col w-full pr-2">
                  <Label
                    title={
                      <span className="flex flex-row w-full">
                        <Text value={[field.label?.en, field.label?.ar]} />
                        {field.connected && (
                          <Text
                            className="ml-2 rounded-full uppercase bg-zyda-green-50 text-green-600 text-xs px-2 h-5 flex items-center"
                            value={translations.CONNECTED}
                          />
                        )}
                      </span>
                    }
                  >
                    <Field
                      type="text"
                      autoComplete="off"
                      after
                      name={field.name}
                      placeholder={field.name}
                      validate={validation}
                      component={Input}
                      disabled
                    />
                  </Label>
                </div>

                <div className="flex items-end">
                  <div
                    role="presentation"
                    className={cx(
                      'flex items-center justify-center h-10 p-2 text-xs font-medium uppercase border rounded',
                      lang === 'ar' ? 'text-left' : 'text-right',
                      field.connected
                        ? 'text-gray-300 border-gray-300'
                        : 'text-primary-base border-primary-base cursor-pointer ',
                    )}
                    onClick={() => {
                      if (!field.connected) {
                        copyToClipboard(field.name);
                        setCopied(field.name);
                      }
                    }}
                  >
                    {copied === field.name ? (
                      <Text value={translations.COPIED} className="inline" />
                    ) : (
                      <Text value={translations.COPY} className="inline" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        );
      })}

      <Taxes />
    </div>
  );
};

export default SecondStep;
