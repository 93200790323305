import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { ORDER_STATUS } from 'constants/order';
import * as translations from 'constants/translations';

const PaymentStatusOrder = ({ lang, paymentStatus }) => {
  switch (paymentStatus) {
    case ORDER_STATUS.WAITING_FOR_PAYMENT:
    case ORDER_STATUS.IFRAME_URL:
    case ORDER_STATUS.REDIRECT_URL:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-zyda-red-500" />
          <div>
            <Text
              value={translations.WAITING_FOR_PAYMENT_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-red-600')}
            />
          </div>
        </div>
      );
    case ORDER_STATUS.PAYMENT_FAILED:
    case ORDER_STATUS.PAYMENT_EXPIRED:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-zyda-red-500" />
          <div>
            <Text
              value={translations.PAYMENT_FAILED_ORDER}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-red-600')}
            />
          </div>
        </div>
      );
    case ORDER_STATUS.PAID:
      return (
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-2 h-2 rounded-full mx-2 bg-zyda-grey-500" />
          <div>
            <Text
              value={translations.PAYMENT_PAID_ORDERS}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-gray-700')}
            />
          </div>
        </div>
      );
    default:
      return '';
  }
};

PaymentStatusOrder.propTypes = {
  lang: PropTypes.string,
  paymentStatus: PropTypes.oneOf([
    ORDER_STATUS.WAITING_FOR_PAYMENT,
    ORDER_STATUS.REDIRECT_URL,
    ORDER_STATUS.PAYMENT_FAILED,
    ORDER_STATUS.PAYMENT_EXPIRED,
    ORDER_STATUS.PAID,
  ]),
};
export default PaymentStatusOrder;
