import React, { useContext } from 'react';
import cx from 'classnames';
import moment from 'moment';
import DateTime from 'react-datetime';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { EXPECTED_DELIVERY_TIME } from 'constants/translations';

const ChangeExpectedAt = ({ initialValues, onChange }) => {
  const { lang, direction } = useContext(localeContext);
  const today = moment().format('MM-DD-YYYY');
  const checkIfDateValid = current => moment(current.format('MM-DD-YYYY')).isSameOrAfter(today);

  return (
    <div className="w-full flex-col">
      <span className="text-sm font-semibold w-full">
        <Text value={EXPECTED_DELIVERY_TIME} />
      </span>
      <div className="w-full flex mt-4" style={{ direction }}>
        <DateTime
          dateFormat={false}
          timeFormat="h:mm A"
          value={initialValues.expectedAt}
          onChange={value => onChange(moment(value))}
          inputProps={{ className: 'w-full' }}
          className="bg-white border border-gray-300 px-2 py-2 w-1/2 focus:outline-none"
        />
        <DateTime
          viewMode="days"
          dateFormat="MMM DD, YYYY"
          timeFormat={false}
          isValidDate={checkIfDateValid}
          value={initialValues.expectedAt}
          onChange={value => onChange(moment(value))}
          inputProps={{ className: 'w-24' }}
          className={cx(
            'bg-white border border-gray-300 px-2 py-2 w-1/2 focus:outline-none',
            lang === 'ar' ? 'mr-4' : 'ml-4',
          )}
        />
      </div>
    </div>
  );
};

ChangeExpectedAt.propTypes = {
  initialValues: PropTypes.shape({ expectedAt: PropTypes.string }),
  onChange: PropTypes.func,
};
export default ChangeExpectedAt;
