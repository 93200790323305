import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import verdDriversQuery from '../../queries/verd/verdDrivers';

type useFetchVerdDriversType = (args: any) => QueryHookResponse<any>;

const useFetchVerdDrivers: useFetchVerdDriversType = args =>
  useQuery<any>({
    query: verdDriversQuery,
    variables: { ...args },
    clientName: GRAPHQL_CLIENT_NAMES.VERD,
    handler: (res: any) => res?.allStoreDrivers,
    config: { revalidateOnMount: true },
  });

export default useFetchVerdDrivers;
