import React, { useContext, useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { ReactComponent as WhatsApp } from 'assets/whatsapp.svg';
import { ReactComponent as Copy } from 'assets/copy.svg';
import * as translations from 'constants/translations';
import Address from '../Address';
import { copyToClipboard } from '../helpers';

const OrderDetailsUserAddress = ({ order, addressGMapURL, orderWhatsAppUrl }) => {
  const addressRef = useRef();
  const [addressCopied, setAddressCopied] = useState(false);

  const { lang } = useContext(localeContext);
  const direction = lang === 'ar' ? 'rtl' : 'ltr';

  const mobileMediumMQ = useMediaQuery({ maxWidth: 375 });
  const mobileLargeMQ = useMediaQuery({ maxWidth: 425 });
  const mediumToLrgMQ = useMediaQuery({ minWidth: 426, maxWidth: 1024 });
  const minMobileLargeMQ = useMediaQuery({ minWidth: 425 });

  useEffect(() => {
    addressCopied && setTimeout(() => setAddressCopied(false), 3000);
  }, [addressCopied]);

  return (
    order.userData.address && (
      <>
        <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
          <span className="block text-sm font-semibold">
            <Text value={translations.DELIVERY_ADDRESS} />
          </span>
          <span
            role="presentation"
            className={cx('cursor-pointer text-primary-base', lang === 'ar' ? 'text-left' : 'text-right')}
            onClick={() => {
              copyToClipboard(addressRef.current.innerText);
              setAddressCopied(true);
            }}
          >
            <Copy className="inline fill-current text-primary-base" />
            <span className="text-primary-base">
              {addressCopied ? (
                <Text value={translations.ADDRESS_COPIED} className="inline" />
              ) : (
                <Text value={translations.COPY_ADDRESS} className="inline" />
              )}
            </span>
          </span>
        </div>
        <div
          className={cx(
            'flex flex-col w-full border-t border-b border-gray-300 py-4 mt-2',
            lang === 'ar' && 'flex-row-reverse',
          )}
        >
          <div className="flex flex-col w-full">
            <span className="block text-sm" style={{ direction }} ref={addressRef}>
              <Address {...order.userData.address} {...order.deliveryZone} />
            </span>
            {order.userData.address.notes && (
              <>
                <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
                  <span className="block text-sm py-1 font-semibold">
                    <Text value={translations.DELIVERY_NOTE} />
                  </span>
                </div>
                <span className={cx(lang === 'ar' && 'text-right')}>{order.userData.address.notes}</span>
              </>
            )}
          </div>
          <div
            className={cx(
              'flex mt-4',
              mediumToLrgMQ && 'mt-1',
              mobileLargeMQ && 'w-full mt-1',
              (mobileLargeMQ || mediumToLrgMQ) && 'flex-wrap',
              lang === 'ar' && minMobileLargeMQ && 'flex-row-reverse',
            )}
          >
            {addressGMapURL && (
              <div
                className={cx(
                  minMobileLargeMQ && !mobileMediumMQ
                    ? lang === 'ar'
                      ? 'w-full xl:pl-1'
                      : 'w-full xl:pr-1'
                    : 'w-full',
                  (mobileLargeMQ || mediumToLrgMQ) && 'w-full',
                )}
                style={{ direction }}
              >
                <Button kind="quartary" size="base" weight="medium" isLink href={addressGMapURL} full>
                  <Text full={false} value={translations.VIEW_MAP} />
                </Button>
              </div>
            )}
            <div
              className={cx(
                minMobileLargeMQ && !mobileMediumMQ ? (lang === 'ar' ? 'w-full xl:pr-1' : 'w-full xl:pl-1') : 'w-full',
                (mobileLargeMQ || mediumToLrgMQ) && 'w-full mt-2',
              )}
              style={{ direction }}
            >
              <Button kind="quartary" size="base" weight="medium" isLink href={orderWhatsAppUrl} full>
                <WhatsApp className="inline fill-current text-primary-base mx-1" />{' '}
                <Text full={false} value={translations.SEND_VIA_WHATS_APP} />
              </Button>
            </div>
          </div>
        </div>
      </>
    )
  );
};

OrderDetailsUserAddress.propTypes = {
  order: PropTypes.shape({
    userData: PropTypes.shape({
      address: PropTypes.shape({
        area: PropTypes.shape({
          cityTitleEn: PropTypes.string,
          cityTitleAr: PropTypes.string,
        }),
        notes: PropTypes.string,
      }),
    }),
    deliveryZone: PropTypes.shape({
      zoneName: PropTypes.string,
    }),
  }),
  addressGMapURL: PropTypes.string,
  orderWhatsAppUrl: PropTypes.string,
};
export default OrderDetailsUserAddress;
