import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useMobile, useSelectedStore } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { ORDER_STATUS } from 'constants/order';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { ORDERS } from 'components/common/orders/Frame/schemas';
import RefundOptionsModal from 'components/common/orders/RefundOptions/RefundOptionsModal';
import { Spinner, Modal, Button } from 'components/kit';
import * as schemas from '../../schemas';
import ChangeExpectedAt from '../../ChangeExpectedAt';
import { isCourierAvailable, generateWhatsAppUrl } from '../helpers';
import RefundInfo from '../RefundInfo';
import OrderDetailsUserCar from './OrderDetailsUserCar';
import OrderDetailsItems from './OrderDetailsItems';
import OrderDetailsUserAddress from './OrderDetailsUserAddress';
import OrderDetailsUserContact from './OrderDetailsUserContact';
import OrderDetailsStatusHistory from './OrderDetailsStatusHistory';
import OrderDetailsFees from './OrderDetailsFees';
import OrderDetailsPrint from './OrderDetailsPrint';
import OrderDetailsGift from './OrderDetailsGift';
import OrderDetailsPaymentInfo from './OrderDetailsPaymentInfo';
import OrderDetailsActionButtons from './OrderDetailsActionButtons';
import { CancelOrder } from './OrderDetailsStatus';
import { DELIVERY_ENUMS } from '../../constants';
import { REFUND_TYPE_ENUM } from './OrderDetailsStatus/CancelOrderBody/data';
import OrderDetailsUmbrella from './OrderDetailsUmbrella';

const OrderDetails = ({
  status: orderDetailsStatus,
  currency,
  selected,
  legalData,
  id,
  ordersVariables,
  deliveryType,
  newOrders,
}) => {
  const storeId = useSelectedStore();
  const user = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const order = useQuery(schemas.ORDER, { variables: { storeId, orderId: id } });

  const [newExpectedAt, setNewExpectedAt] = useState(null);

  const updateStatusUpdateCache = (
    cache,
    {
      data: {
        updateOrderStatus: { status: newStatus },
      },
    },
  ) => {
    const orders = cache.readQuery({
      query: ORDERS,
      variables: ordersVariables,
    });
    const statuses = orders.orders.statusCount;
    const oldStatus = orders.orders.orders.find(({ id: oId }) => oId === id).status;
    cache.writeQuery({
      query: ORDERS,
      variables: ordersVariables,
      data: {
        orders: {
          ...orders.orders,
          statusCount:
            oldStatus !== newStatus
              ? {
                  ...statuses,
                  [orderDetailsStatus]: statuses[orderDetailsStatus] - 1,
                  [newStatus]: statuses[newStatus] + 1,
                }
              : { ...statuses },
        },
      },
    });
  };

  const updateStatusAsyncUpdateCache = (
    cache,
    {
      data: {
        updateOrderStatusAsync: { status: newStatus, inBetweenTransitions },
      },
    },
  ) => {
    const orders = cache.readQuery({
      query: ORDERS,
      variables: ordersVariables,
    });
    const statuses = orders.orders.statusCount;
    const oldOrder = orders.orders.orders.find(({ id: oId }) => oId === id);
    const ordersWithoutOldOne = orders.orders.orders.filter(({ id: oId }) => oId !== id);
    const newUpdatedOrders = [{ ...oldOrder, inBetweenTransitions }, ...ordersWithoutOldOne];

    cache.writeQuery({
      query: ORDERS,
      variables: ordersVariables,
      data: {
        orders: {
          ...orders.orders,
          orders: newUpdatedOrders,
          statusCount:
            oldOrder.status !== newStatus
              ? {
                  ...statuses,
                  [orderDetailsStatus]: statuses[orderDetailsStatus] - 1,
                  [newStatus]: statuses[newStatus] + 1,
                }
              : { ...statuses },
        },
      },
    });
  };

  const updateStatusOnError = ({ graphQLErrors }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ extensions }) => {
        const { body } = extensions.exception;
        if (body.error) {
          return notifications.show(body.error, 'error');
        }
        if (body.delivery_courier) {
          return notifications.show(body.delivery_courier, 'error');
        }
        return notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      });
  };

  const [updateStatusAsync] = useMutation(schemas.UPDATE_STATUS_ASYNC, {
    update: updateStatusAsyncUpdateCache,
    onError: updateStatusOnError,
  });

  const [updateStatus, { loading: isUpdatingStatus }] = useMutation(schemas.UPDATE_STATUS, {
    update: updateStatusUpdateCache,
    onError: updateStatusOnError,
  });
  const { lang } = useContext(localeContext);
  const isMobile = useMobile();

  const {
    data: {
      order: {
        id: orderId,
        number,
        userData = {},
        total,
        beachUmbrella,
        deliveryCourier,
        deliveryCourierId,
        status: orderStatus,
        updatingStatus,
      } = {},
      order: orderData,
    } = {},
  } = order;

  const { area: { lat: areaLat, lng: areaLng } = {} } =
    userData.address && userData.address.area ? userData.address : {};

  const { lat, lng } = userData.address ? userData.address : {};

  const userLat = lat || areaLat;
  const userLng = lng || areaLng;
  const addressGMapURL = userLat && userLng && `https://www.google.com/maps/search/?api=1&query=${userLat},${userLng}`;
  const paidThrough = orderData?.paidThrough || '';
  const isBulkChange = updatingStatus?.orderGettingUpdated;
  const { zoneName } = orderData?.deliveryZone ? orderData?.deliveryZone : {};

  const orderWhatsAppUrl =
    orderData &&
    generateWhatsAppUrl({
      lang,
      currency,
      number,
      total,
      userData,
      addressGMapURL,
      paidThrough,
      zoneName,
    });

  const [updatedCourierId, setUpdatedCourierId] = useState(deliveryCourierId);
  const { selectedStore, isPosCourier: isPosBusiness, isDeliveryCourier: isDeliveryBusiness, courier } = useContext(
    userContext,
  );
  const orderCurrency = lang === 'en' ? selectedStore.currency.titleEn : selectedStore.currency.titleAr;

  const orderTotalWithCurrecy = `${total} ${orderCurrency}`;

  const sendChangeStatus = async ({ status, sendCourierId, async = false }) => {
    const payload = {
      variables: {
        storeId,
        orderId: id,
        status,
        ...(deliveryType === DELIVERY_ENUMS.DELIVERY_SMALL &&
          courier &&
          sendCourierId && {
            deliveryCourierId: updatedCourierId,
          }),
      },
    };
    async ? await updateStatusAsync(payload) : await updateStatus(payload);
    newOrders.markAsRead(id);
  };
  useEffect(() => {
    setUpdatedCourierId(deliveryCourierId);
  }, [deliveryCourierId]);

  const hasCourier = isDeliveryBusiness && updatedCourierId;
  const deliveryOrderStatus = deliveryCourier?.deliveryOrderStatus;
  const isDeliveryOrderStatusCancelledOrDeclined =
    deliveryOrderStatus === ORDER_RIDER_STATUS.CANCELED || deliveryOrderStatus === ORDER_RIDER_STATUS.DECLINED;
  const isOrderDeliveredOrCancelled = orderStatus === ORDER_STATUS.DELIVERED || orderStatus === ORDER_STATUS.CANCELED;

  const showChangeExpectedAt = !isDeliveryOrderStatusCancelledOrDeclined && !isOrderDeliveredOrCancelled && hasCourier;

  // If the courier has a socket integration to continue process cycle ( dispatch and next )
  const hasTwoWaySync = courier?.businessType === DELIVERY_ENUMS.DELIVERY && !courier?.isManualStatusUpdate;

  const canBeSendToCourier =
    order?.data?.order &&
    isCourierAvailable({
      deliveryType,
      restaurantCourierSetting: courier,
      isDeliveryBusiness,
    });

  return (
    <Modal>
      {({ open, close }) =>
        order.loading ? (
          <Spinner />
        ) : (
          <>
            <div
              className={cx('flex', lang === 'ar' && 'flex-row-reverse', 'flex-wrap items-start bg-gray-100 p-4')}
              data-testid="order-item-body"
            >
              <div
                className={cx(
                  'w-full md:w-2/3 flex flex-col items-start',
                  lang === 'ar' ? 'md:pl-3' : 'md:pr-3',
                  isMobile && 'mb-4',
                )}
              >
                <span className="text-sm font-semibold w-full">
                  <Text value={translations.ITEMS} />
                </span>
                <OrderDetailsItems order={order.data?.order} currency={currency} />
                <OrderDetailsFees order={order.data?.order} currency={currency} deliveryType={deliveryType} />

                <div className="flex flex-col w-full mt-4">
                  {[
                    REFUND_TYPE_ENUM.CREDIT_CARD,
                    REFUND_TYPE_ENUM.ORDERFAST_WALLET,
                    REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD,
                  ].includes(order.data.order.typeOfRefund) && <RefundInfo {...order.data.order} lang={lang} />}
                  {!canBeSendToCourier && (
                    <OrderDetailsPrint
                      order={order.data.order}
                      canBeSendToCourier={canBeSendToCourier}
                      selected={selected}
                      legalData={legalData}
                      isDeliveryBusiness={isDeliveryBusiness}
                      deliveryCourier={deliveryCourier}
                      courierDetails={courier?.courierDetails}
                      orderStatus={orderStatus}
                    />
                  )}

                  <div
                    className={cx(
                      'flex',
                      lang === 'ar' && 'flex-row-reverse',
                      isMobile && 'flex-wrap-reverse md:flex-nowrap',
                      'justify-between',
                    )}
                  >
                    <div className="flex flex-col justify-between">
                      {showChangeExpectedAt && (
                        <div className="mb-4">
                          <ChangeExpectedAt
                            initialValues={{
                              expectedAt: newExpectedAt
                                ? moment(newExpectedAt).tz(selectedStore.timeZone)
                                : moment(order.data.order.expectedAt).tz(selectedStore.timeZone),
                            }}
                            onChange={data => setNewExpectedAt(data)}
                          />
                        </div>
                      )}

                      {canBeSendToCourier && (
                        <div className="mb-4">
                          <OrderDetailsPrint
                            order={order.data.order}
                            canBeSendToCourier={canBeSendToCourier}
                            selected={selected}
                            legalData={legalData}
                            isDeliveryBusiness={isDeliveryBusiness}
                            deliveryCourier={deliveryCourier}
                            courierDetails={courier?.courierDetails}
                            orderStatus={orderStatus}
                          />
                        </div>
                      )}

                      <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
                        <CancelOrder
                          isPosBusiness={isPosBusiness}
                          order={order.data.order}
                          isBulkChange={isBulkChange}
                          open={open}
                          ordersVariables={ordersVariables}
                          close={close}
                          status={orderDetailsStatus}
                          hasCourier={hasCourier}
                          isMobile={isMobile}
                          lang={lang}
                        />
                      </div>
                    </div>

                    {(user.hasRole('owner') || user.hasRole('ops_manager')) &&
                      order.data.order.status === ORDER_STATUS.CANCELED &&
                      [ORDER_STATUS.PAID, ORDER_STATUS.REFUND_FAILED].includes(order.data.order.paymentStatus) &&
                      ![
                        REFUND_TYPE_ENUM.CREDIT_CARD,
                        REFUND_TYPE_ENUM.ORDERFAST_WALLET,
                        REFUND_TYPE_ENUM.WALLET_AND_CREDIT_CARD,
                      ].includes(order.data.order.typeOfRefund) &&
                      !['CARDONDELIVERY', 'Cash', 'CASH'].includes(order.data.order.paidThrough) && (
                        <Button
                          onClick={() =>
                            open({
                              title: (
                                <Text
                                  value={translations.REFUND_OPTIONS_MODAL_TITLE(number, orderTotalWithCurrecy)}
                                  className="text-black font-medium"
                                />
                              ),
                              body: (
                                <RefundOptionsModal
                                  open={open}
                                  close={close}
                                  lang={lang}
                                  orderPaidThrough={paidThrough}
                                  orderId={orderId}
                                  orderNumber={number}
                                  currency={orderCurrency}
                                  orderTotal={total}
                                />
                              ),
                              size: 'max-w-sm',
                            })
                          }
                          kind="tertiary"
                          textColor="text-red-600"
                          size="base"
                        >
                          <Text value={translations.REFUND_ORDER} />
                        </Button>
                      )}

                    <OrderDetailsActionButtons
                      order={order.data.order}
                      hasCourier={hasCourier}
                      isUpdatingStatus={isUpdatingStatus || order.data.order.inBetweenTransitions}
                      isBulkChange={isBulkChange}
                      sendChangeStatus={sendChangeStatus}
                      open={open}
                      close={close}
                      updatedCourierId={updatedCourierId}
                      selectedStore={selectedStore}
                      newExpectedAt={newExpectedAt}
                      hasTwoWaySync={hasTwoWaySync}
                      deliveryType={deliveryType}
                    />
                  </div>
                </div>
                <OrderDetailsPaymentInfo order={order.data.order} selected={selected} />
              </div>
              <div className={cx('w-full md:w-1/3', lang === 'ar' ? 'md:pr-3' : 'md:pl-3')}>
                <div className="flex flex-col">
                  <OrderDetailsUserAddress
                    order={order.data.order}
                    orderWhatsAppUrl={orderWhatsAppUrl}
                    addressGMapURL={addressGMapURL}
                  />
                  <OrderDetailsUserContact order={order.data.order} />
                  {beachUmbrella && <OrderDetailsUmbrella order={order.data.order} />}
                  <OrderDetailsGift order={order.data.order} />
                  <OrderDetailsUserCar order={order.data.order} deliveryType={deliveryType} />
                  <OrderDetailsStatusHistory
                    order={order.data.order}
                    selected={selected}
                    isDeliveryBusiness={isDeliveryBusiness}
                    hasCourier={hasCourier}
                  />
                </div>
              </div>
            </div>
          </>
        )
      }
    </Modal>
  );
};

OrderDetails.propTypes = {
  status: PropTypes.string,
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
  selected: PropTypes.shape({ restaurantCourierSetting: PropTypes.string }),
  id: PropTypes.number.isRequired,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  deliveryType: PropTypes.string,
  legalData: PropTypes.shape({
    companyNameEn: PropTypes.string,
    companyNameAr: PropTypes.string,
    vatIdNumber: PropTypes.string,
  }),
  newOrders: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.number,
    sort: PropTypes.objectOf({ order: PropTypes.string, field: PropTypes.string }),
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
};
export default OrderDetails;
