import React from 'react';
import { ReactComponent as ChampionsStarIcon } from 'assets/champions-star-icon.svg';
import { ReactComponent as LoyalCustomersHeartIcon } from 'assets/loyal.svg';
import { ReactComponent as PromisingMoneyBagIcon } from 'assets/money-bag-dollar.svg';
import { ReactComponent as NewCustomersIcon } from 'assets/segment-filter-new.svg';
import { ReactComponent as LowValueIcon } from 'assets/saving-money-flower.svg';
import { ReactComponent as NeedsAttentionIcon } from 'assets/cash-payment-coins-grey.svg';
import { ReactComponent as AllCustomersIcon } from 'assets/campaign-segment-all-customers.svg';
import {
  SEGMENT_TITLE_ALL_CUSTOMERS,
  SEGMENT_TITLE_CHAMPIONS,
  SEGMENT_TITLE_LOYAL,
  SEGMENT_TITLE_PROMISING,
  SEGMENT_TITLE_NEW,
  SEGMENT_TITLE_LOW_VALUE,
  SEGMENT_TITLE_NEEDS_ATTENTION,
} from 'constants/translations';

export const CUSTOMERS_SEARCH_BY_OPTIONS = {
  PHONE_NUMBER: 'phoneNumber',
  USER_NAME: 'userName',
};

export const CUSTOMER_TABLE_KEYS = {
  USER_NAME: 'userName',
  PHONE_NUMBER: 'phoneNumber',
  FIRST_ORDER: 'firstOrder',
  LAST_ORDER: 'lastOrder',
  TOTAL_ORDERS: 'totalOrders',
  TOTAL_SALES: 'totalSales',
  SEGMENT: 'segment',
};

export const CUSTOMERS_SORT_ORDER = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

export const SEGMENT_TITLE_MAP = {
  ALL_CUSTOMERS: 0,
  CHAMPIONS: 1,
  LOYAL: 2,
  PROMISING: 3,
  NEW: 4,
  LOW_VALUE: 5,
  NEEDS_ATTENTION: 6,
};

const segmentStaticAttributes = {
  descriptionEn: '',
  descriptionAr: '',
  customersCount: '',
};

export const initialSegments = {
  0: {
    segmentId: SEGMENT_TITLE_MAP.ALL_CUSTOMERS,
    titleEn: SEGMENT_TITLE_ALL_CUSTOMERS[0],
    titleAr: SEGMENT_TITLE_ALL_CUSTOMERS[1],
    segmentIcon: <AllCustomersIcon />,
    segmentBaseCss: 'bg-gray-100',
    segmentFilterCss: 'border-gray-500',
    segmentLabelCss: 'text-gray-500',
    ...segmentStaticAttributes,
  },
  1: {
    segmentId: SEGMENT_TITLE_MAP.CHAMPIONS,
    titleEn: SEGMENT_TITLE_CHAMPIONS[0],
    titleAr: SEGMENT_TITLE_CHAMPIONS[1],
    segmentIcon: <ChampionsStarIcon />,
    segmentBaseCss: 'bg-zyda-purple-50',
    segmentFilterCss: 'border-zyda-purple-500',
    segmentLabelCss: 'text-zyda-purple-500',
    ...segmentStaticAttributes,
  },
  2: {
    segmentId: SEGMENT_TITLE_MAP.LOYAL,
    titleEn: SEGMENT_TITLE_LOYAL[0],
    titleAr: SEGMENT_TITLE_LOYAL[1],
    segmentIcon: <LoyalCustomersHeartIcon />,
    segmentBaseCss: 'bg-primary-25',
    segmentFilterCss: 'border-primary-base',
    segmentLabelCss: 'text-primary-base',
    ...segmentStaticAttributes,
  },
  3: {
    segmentId: SEGMENT_TITLE_MAP.PROMISING,
    titleEn: SEGMENT_TITLE_PROMISING[0],
    titleAr: SEGMENT_TITLE_PROMISING[1],
    segmentIcon: <PromisingMoneyBagIcon />,
    segmentBaseCss: 'bg-zyda-green-25',
    segmentFilterCss: 'border-zyda-green-150',
    segmentLabelCss: 'text-zyda-green-150',
    ...segmentStaticAttributes,
  },
  4: {
    segmentId: SEGMENT_TITLE_MAP.NEW,
    titleEn: SEGMENT_TITLE_NEW[0],
    titleAr: SEGMENT_TITLE_NEW[1],
    segmentIcon: <NewCustomersIcon />,
    segmentBaseCss: 'bg-zyda-yellow-25',
    segmentFilterCss: 'border-zyda-yellow-150',
    segmentLabelCss: 'text-zyda-yellow-150',
    ...segmentStaticAttributes,
  },
  5: {
    segmentId: SEGMENT_TITLE_MAP.LOW_VALUE,
    titleEn: SEGMENT_TITLE_LOW_VALUE[0],
    titleAr: SEGMENT_TITLE_LOW_VALUE[1],
    segmentIcon: <LowValueIcon />,
    segmentBaseCss: 'bg-zyda-red-25',
    segmentFilterCss: 'border-zyda-red-150',
    segmentLabelCss: 'text-zyda-red-150',
    ...segmentStaticAttributes,
  },
  6: {
    segmentId: SEGMENT_TITLE_MAP.NEEDS_ATTENTION,
    titleEn: SEGMENT_TITLE_NEEDS_ATTENTION[0],
    titleAr: SEGMENT_TITLE_NEEDS_ATTENTION[1],
    segmentIcon: <NeedsAttentionIcon />,
    segmentBaseCss: 'bg-primary-25',
    segmentFilterCss: 'border-zyda-grey-150',
    segmentLabelCss: 'text-gray-700',
    ...segmentStaticAttributes,
  },
};

export const SEGMENT_ATTRIBUTES = { CUSTOMERS_COUNT: 'customersCount' };

export const CUSTOMER_PROFILE_ORDERS_SORT_BY = { CREATED_AT: 'created_at' };

export const CUSTOMER_PROFILE_ORDERS_SORT_BY_ORDER = { DESCENDING: 'descend' };
