export const main = '/*';
export const facebookConnect = '/facebook-connect';
export const switchStore = '/:storeId/switch-store';

export const orders = '/:storeId/orders';
export const allOrders = '/:storeId/orders/all';
export const pendingOrders = '/:storeId/orders/pending';
export const acceptedOrders = '/:storeId/orders/accepted';
export const readyOrders = '/:storeId/orders/ready';
export const dispatchedOrders = '/:storeId/orders/dispatched';
export const pickedUpOrders = '/:storeId/orders/picked-up';
export const deliveredOrders = '/:storeId/orders/delivered';
export const canceledOrders = '/:storeId/orders/canceled';
export const refundedOrders = '/:storeId/orders/refunded';
export const fulfilledOrders = '/:storeId/orders/fulfilled';
export const completedOrders = '/:storeId/orders/completed';
export const waitingForPaymentOrders = '/:storeId/orders/waiting-for-payment';
export const paymentFailedOrders = '/:storeId/orders/payment-failed';
export const pushFailedOrders = '/:storeId/orders/push-failed';

export const analytics = '/:storeId/analytics';
export const salesAnalytics = '/:storeId/analytics/sales-analytics';
export const salesBreakdown = '/:storeId/analytics/sales-analytics/sales-breakdown';
export const behaviorBreakdown = '/:storeId/analytics/behavior-breakdown';
export const breakdownDeliveryPickup = '/:storeId/analytics/behavior-breakdown/delivery-pickup';
export const breakdownPaymentMethods = '/:storeId/analytics/behavior-breakdown/payment-methods';
export const breakdownNewVsReturning = '/:storeId/analytics/behavior-breakdown/new-vs-returning-customers';
export const bestSelling = '/:storeId/analytics/best-selling';
export const productsAnalytics = '/:storeId/analytics/best-selling/products';
export const geoAnalytics = '/:storeId/analytics/branches-delivery-areas';
export const geoAnalyticsBranches = '/:storeId/analytics/branches-delivery-areas/branches';
export const geoAnalyticsBranchAreas = '/:storeId/analytics/branches-delivery-areas/:branchId';

export const reports = '/:storeId/reports';
export const oldsalesReports = '/:storeId/reports/sales';
export const customersReports = '/:storeId/reports/customers';
export const salesReports = '/:storeId/reports/payment-transactions';

export const setup = '/:storeId/setup';
export const branding = '/:storeId/setup/branding';
export const businessInformation = '/:storeId/setup/business-information';
export const branchList = '/:storeId/setup/locations';
export const branchCreation = '/:storeId/setup/locations/create';
export const branchCreationSuccess = '/:storeId/setup/locations/create/success';
export const branchEdition = '/:storeId/setup/locations/:branchId';
export const deliveryList = '/:storeId/setup/delivery';
export const deliveryCreation = '/:storeId/setup/delivery/create';
export const deliveryEdition = '/:storeId/setup/delivery/:branchId/delivery-zones/:deliveryZoneId';
export const zonesCreation = '/:storeId/setup/delivery/map';
export const deliveryZoneTemplates = '/:storeId/setup/delivery/zones/templates/:countryCode';
export const deliveryZoneTemplatesByCity = '/:storeId/setup/delivery/zones/templates/:countryCode/:cityCode';
export const zonesTableList = '/:storeId/setup/delivery/list';

// RETAIL PRODUCT CATALOG PATHS
export const products = '/:storeId/products/catalog';
export const productsCatalog = '/:storeId/products/catalog';
export const productsOptionGroups = '/:storeId/products/catalog/option-groups';
export const baseProductCreation = '/:storeId/products/catalog/add/base';
export const advancedProductCreation = '/:storeId/products/catalog/add/advanced/:productId';
export const previewProductCreation = '/:storeId/products/catalog/add/preview';
export const productEdition = '/:storeId/products/catalog/:productId';
export const productStockManagement = '/:storeId/products/catalog/stock-management';
export const productStockVariants = '/:storeId/products/catalog/stock-management/variants';
export const productStockSettings = '/:storeId/products/catalog/stock-management/settings';
export const productStockHistory = '/:storeId/products/catalog/stock-management/history';

export const ordersManagement = '/:storeId/setup/orders';
export const ordersManagementScheduling = '/:storeId/setup/orders/scheduling';
export const integrations = '/:storeId/setup/integrations';
export const integrationsStatus = '/:storeId/setup/integrations/status';

export const signIn = '/sign-in';
export const forgetPassword = '/forgot-password';
export const resetPassword = '/passwordReset/:resetToken';
export const onboarding = {
  storeCreation: '/onboarding/store-creation',
  gettingStarted: '/onboarding/getting-started',
};

// MENU PATHS
export const menu = '/:storeId/menu';
export const menuOverview = '/:storeId/menu/overview';
export const menuOptionGroups = '/:storeId/menu/option-groups';
export const operatorOptionGroups = '/:storeId/optionGroups';
export const stockManagement = '/:storeId/menu/stock-management';
export const itemTracking = '/:storeId/itemTracking';
export const stockManagementVariants = '/:storeId/menu/stock-management/variants';
export const variantItemTracking = '/:storeId/menu/item-tracking/variants';
export const stockManagementGeneralSettings = '/:storeId/menu/stock-management/settings';
export const stockHistoryLog = '/:storeId/menu/stock-management/history';
export const itemTrackingHistoryLog = '/:storeId/menu/item-tracking/history';

export const storeLayout = '/:storeId/setup/store-layout';
export const paymentGateways = '/:storeId/setup/payment-gateways';
export const paymentGateway = '/:storeId/setup/payment-gateways/payment-gateway';
export const onlinePaymentCreationForm = '/:storeId/setup/payment-gateways/payment-methods/Creation';
export const onlinePaymentEditionForm = '/:storeId/setup/payment-gateways/payment-methods/:paymentId';
export const team = '/:storeId/team';
export const verd = '/:storeId/verd';
export const billing = '/:storeId/billing';
export const editUserInfo = '/:storeId/userinfoedit';
export const changePassword = '/:storeId/userinfoedit/changePassword';
export const renew = '/:storeId/billing/renew';
export const invoices = '/:storeId/billing/invoices';
export const createUser = '/:storeId/team/create';
export const editUser = '/:storeId/team/edit/:userId';
export const createRider = '/:storeId/verd/create';
export const editRider = '/:storeId/verd/edit/:riderId';
export const webLinks = '/:storeId/setup/web-links';

export const marketing = '/:storeId/marketing';
export const vouchers = '/:storeId/marketing/vouchers';
export const allVouchers = '/:storeId/marketing/vouchers/all';
export const activeVouchers = '/:storeId/marketing/vouchers/activate';
export const deactivatedVouchers = '/:storeId/marketing/vouchers/deactivated';
export const scheduleVouchers = '/:storeId/marketing/vouchers/schedule';
export const expiredVouchers = '/:storeId/marketing/vouchers/expired';
export const createVoucher = '/:storeId/marketing/vouchers/create';
export const editVoucher = '/:storeId/marketing/vouchers/edit/:voucherId';

export const socialmediatracking = '/:storeId/marketing/socialmedia-tracking';
export const campaigns = '/:storeId/marketing/campaigns';
export const campaignSubscriptions = '/:storeId/marketing/campaigns/subscriptions';
export const createCampaign = '/:storeId/marketing/campaigns/create';
export const editCampaign = '/:storeId/marketing/campaigns/edit/:campaignId';

export const customers = '/:storeId/customers';
export const customerProfile = '/:storeId/customers/profile/:customerId';

export const oldProducts = '/:storeId/setup/products';
export const oldProductsCatalog = '/:storeId/setup/products/catalog';
export const oldProductsOptionGroups = '/:storeId/setup/products/option-groups';
export const oldBaseProductCreation = '/:storeId/setup/products/add/base';
export const oldAdvancedProductCreation = '/:storeId/setup/products/add/advanced/:productId';
export const oldPreviewProductCreation = '/:storeId/setup/products/add/preview';
export const oldProductEdition = '/:storeId/setup/products/:productId';
export const oldStockManagement = '/:storeId/setup/stock-management';
export const oldStockManagementVariants = '/:storeId/setup/stock-management/variants';
export const oldStockManagementGeneralSettings = '/:storeId/setup/stock-management/settings';
export const oldStockHistoryLog = '/:storeId/setup/stock-management/history';

export const orderfast = '/:storeId/orderfast';
export const links = '/:storeId/orderfast/links';
export const loyalty = '/:storeId/orderfast/loyalty';
export const wallet = '/:storeId/orderfast/wallet';
