import React, { useContext } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { LocationMarkerIcon } from '@heroicons/react/outline';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import OrderRiderStatus from 'components/common/verd/OrderRiderStatus';
import { Button, SpinnerAlt } from 'components/kit';
import { ORDER_STATUS } from 'constants/order';

const StoreCourierInfo = ({
  deliveryOrderStatus,
  driverName,
  referenceId,
  hasDriverInfo,
  driverAssigned,
  orderDeliveryPhoneNumber,
  trackingLink,
  isScheduledOrder,
  firingTime,
}) => {
  const { lang, direction } = useContext(localeContext);
  const isArabic = lang === 'ar';
  const { selectedStore } = useContext(userContext);
  return (
    <div className="bg-gray-200 mb-4">
      <div className={cx('bg-gray-200 flex justify-between py-2 px-4', isArabic ? 'flex-row-reverse' : 'flex-row')}>
        <Text value={translations.COURIER_INFO} className="font-medium" />
        {trackingLink && (
          <div className="flex-1 flex flex-col">
            <Button
              kind="ghost"
              weight="normal"
              textColor="text-primary-base"
              size="small"
              isLink
              className="flex"
              href={trackingLink}
              icon={<LocationMarkerIcon className="w-5 h-5" />}
            >
              <Text value={translations.TRACK_RIDER} className="text-center" full={false} />
            </Button>
          </div>
        )}
      </div>
      {isScheduledOrder && deliveryOrderStatus === ORDER_STATUS.PENDING ? (
        <div
          className={cx(
            'flex justify-between px-2 py-4 mx-1 mb-1 bg-white',
            isArabic ? 'flex-col-reverse' : 'flex-col',
          )}
        >
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <Text value={translations.REQUEST_STATUS} className="text-xs text-zyda-grey-400" />
              <Text value={translations.WILL_BE_ASSIGNED} className="text-yellow-600" />
              <div className={cx('flex', isArabic ? 'flex-row-reverse' : 'flex-row')}>
                <span className="text-zyda-grey-500 text-xs">
                  {moment(firingTime)
                    .tz(selectedStore.timeZone)
                    .locale(isArabic ? 'ar' : 'en-gb')
                    .format('h:mm A, DD MMM YYYY')}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={cx(
            'flex justify-between px-4 py-4 mx-1 mb-1 bg-white',
            isArabic ? 'flex-row-reverse' : 'flex-row',
          )}
        >
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <Text value={translations.REQUEST_STATUS} className="text-xs text-zyda-grey-400" />
              <OrderRiderStatus status={deliveryOrderStatus} lang={lang} />
            </div>
            {hasDriverInfo && deliveryOrderStatus !== ORDER_STATUS.DECLINED && (
              <div className="flex flex-col mt-2">
                <Text value={translations.DRIVER_NAME} className="text-xs text-zyda-grey-400" />
                <div className="flex items-center">
                  {!driverAssigned && (
                    <div className={cx('flex', isArabic ? 'flex-row-reverse' : 'flex-row')}>
                      <span className={cx('w-4 h-4', direction === 'rtl' ? 'ml-2' : 'mr-2')}>
                        <SpinnerAlt color="primary-base" />
                      </span>
                    </div>
                  )}
                  {driverAssigned ? (
                    <div className={cx('flex', isArabic ? 'flex-row-reverse' : 'flex-row')}>
                      <span className="text-xs text-primary-base">{driverName}</span>
                    </div>
                  ) : (
                    <Text value={translations.SEARCHING_FOR_A_DRIVER} />
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col justify-between">
            <div className="flex flex-col">
              <Text value={translations.REQUEST_ID} className="text-xs text-zyda-grey-400" />
              <div className={cx('flex', isArabic ? 'flex-row-reverse' : 'flex-row')}>
                <span className="text-xs text-primary-base">{referenceId || '-'}</span>
              </div>
            </div>
            {driverAssigned && (
              <div className="flex flex-col mt-2">
                <Text value={translations.PHONE_NUMBER} className="text-xs text-zyda-grey-400" />
                <div className={cx('flex', isArabic ? 'flex-row-reverse' : 'flex-row')}>
                  <span className="text-xs text-primary-base">{orderDeliveryPhoneNumber || '-'}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreCourierInfo;
