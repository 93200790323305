import { GRAPHQL_CLIENT_NAMES } from 'service/constants';
import { apiMutate } from 'service/utils/mutation';
import { updateStoreDriverMutation } from '../../mutations/verd';

import { UpdateStoreDriverMutationInput, UpdateStoreDriverMutationPayload } from '../../types/generated';

type useUpdateStoreDriverType = () => ({
  input,
}: {
  input?: UpdateStoreDriverMutationInput;
}) => Promise<void | Partial<UpdateStoreDriverMutationPayload> | { hasError: boolean }>;

const useUpdateStoreDriver: useUpdateStoreDriverType = () => input =>
  apiMutate({
    mutation: updateStoreDriverMutation,
    variables: { input },
    clientName: GRAPHQL_CLIENT_NAMES.VERD,
    requestHeaders: {},
    handler: (res: any) => res,
  });

export default useUpdateStoreDriver;
