import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const OrderDetailsUserCar = ({ order, deliveryType }) => {
  const { lang } = useContext(localeContext);
  const { userData } = order || {};
  const { car } = userData || {};
  const { make, model, color, license } = car || {};

  return (
    order &&
    userData &&
    car &&
    deliveryType === 'pickup' && (
      <div className="mt-4">
        <span className="block text-sm font-semibold">
          <Text value={translations.CAR_INFO} />
        </span>
        <div className={cx('w-full border-t border-b border-gray-300 py-3 mt-1', lang === 'ar' && 'text-right')}>
          {make && <div className="text-sm py-1 font-extralight">{make}</div>}
          {model && <div className="text-sm py-1 font-extralight">{model}</div>}
          {color && <div className="text-sm py-1 font-extralight">{color}</div>}
          {license && <div className="text-sm py-1 font-extralight">{license}</div>}
        </div>
      </div>
    )
  );
};

OrderDetailsUserCar.propTypes = {
  order: PropTypes.shape({
    userData: PropTypes.shape({
      car: PropTypes.shape({
        make: PropTypes.string,
        model: PropTypes.string,
        color: PropTypes.string,
        license: PropTypes.string,
      }),
    }),
  }),
  deliveryType: PropTypes.string.isRequired,
};

export default OrderDetailsUserCar;
