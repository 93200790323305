import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import { Box, Spinner } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { DELIVERECT } from 'constants/integrations';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import FeatureAccess from 'common/lib/FeatureAccess';
import { context as userContext } from 'context/user';
import { context as featureBlockingContext } from 'context/feature-blocking';
import * as schemas from '../schemas';
import POSCard from '../Card';

const POSIntegration = ({ open, close, closeable }) => {
  const { user, selectedStore } = useContext(userContext);
  const { featureAccess } = useContext(featureBlockingContext);
  const location = useLocation();
  const featureAccessMap = featureAccess
    .get(ACCESS_MAP_KEYS.FEATURES)
    ?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id));

  const { data: { pos } = {}, loading } = useQuery(schemas.LIST_POS, {
    variables: {
      isPublished: true,
    },
  });

  const renderPosCard = _pos => (
    <div key={_pos.name} className="mt-4">
      <POSCard
        onOpen={open}
        onClose={close}
        closeable={closeable}
        courier={_pos}
        courierType="pos"
        isBlocked={featureAccessMap?.[_pos?.name?.toUpperCase()]?.isBlocked}
      />
    </div>
  );

  const displayPOS = _pos => {
    if (
      (_pos.name === DELIVERECT && _pos.integrationData?.whiteListedEmails?.includes(user?.email)) ||
      _pos.name !== DELIVERECT
    )
      return renderPosCard(_pos);
    return null;
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          title={<Text className="font-semibold text-lg" value={translations.POINT_OF_SALE} />}
          body={<div className="pb-2 px-4">{pos?.length && pos.map(_pos => displayPOS(_pos))}</div>}
        />
      )}
    </>
  );
};

POSIntegration.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  closeable: PropTypes.func.isRequired,
};

export default POSIntegration;
