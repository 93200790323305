import React, { useContext, useState, Fragment } from 'react';
import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';
import { isObject } from 'formik';
import cx from 'classnames';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import { Button, Stack } from 'components/kit';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { useReplaceParams } from 'hooks';
import * as translations from 'constants/translations';
import * as paths from 'paths.js';
import { RESTAURANT_COURIER_AUTHENTICATION_TYPE } from 'constants/restaurant';
import { DELIVERECT as DELIVERECT_VAL, OCIMS } from 'constants/integrations';
import { EXTERNAL_RESPONSE } from 'constants/constant-errors';
import convertMultiLanguagesFromObjectToArray from '../../../utils';
import IntegrationSection from './IntegrationSection';
import DynamicSection from './DynamicSection';
import LearnMore from './LearnMore';
import * as schemas from '../schemas';
import SubmitButton from './SubmitButton';
import Deliverect from './Deliverect';
import Ocims from './Ocims';

const ModalBody = ({ onCancel, settings, changeSettings, closeable, courier, courierType, defaultStep = 1 }) => {
  const notifications = useContext(notificationsContext);
  const replaceParams = useReplaceParams();
  const { direction, translate, lang } = useContext(localeContext);
  const {
    integrationData: {
      courierBasicInfo: { title, description },
      integrationsConstraints,
      courierIntegrationInfo,
      learnMore,
      courierDynamicSection,
    },
  } = courier;

  const { selectedStore } = useContext(userContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [authData, setAuthData] = useState({});
  const [extraData, setExtraData] = useState({});
  const titleParsedToArray = convertMultiLanguagesFromObjectToArray(title);

  const onComplete = () => {
    changeSettings();
    onCancel();
    closeable(true);
    notifications.show(translations.AUTHORIZED_SUCCESSFULLY);
  };

  const onError = error => {
    if (!error.graphQLErrors) throw error;
    Object.keys(error.graphQLErrors[0].extensions.exception.body).forEach(key => {
      if (key === EXTERNAL_RESPONSE) {
        if (isObject(error.graphQLErrors[0].extensions.exception.body[key])) {
          notifications.show(error.graphQLErrors[0].extensions.exception.body[key]?.message, 'error');
        } else {
          notifications.show(
            JSON.parse(error.graphQLErrors[0].extensions.exception.body[key]).restaurant_courier_settings,
            'error',
            () => navigate(replaceParams(paths.ordersManagementScheduling)),
          );
        }
      }
    });
    closeable(true);
  };

  const [authorizeCourier, { loading: authorizeCourierLoading }] = useMutation(schemas.AUTHORIZE, {
    onCompleted: onComplete,
    onError,
  });

  const [authorizeCourierBranches, { loading: authorizeCourierBranchesLoading }] = useMutation(
    schemas.AUTHORIZE_BRANCHES,
    {
      onCompleted: onComplete,
      onError,
    },
  );

  const callAPI = async () => {
    closeable(false);
    switch (courier.authenticationType) {
      case RESTAURANT_COURIER_AUTHENTICATION_TYPE.BRANCH_KEY:
        await authorizeCourierBranches({
          variables: {
            apiKey: R.compose(R.values)(authData),
            ...extraData,
            restaurantId: selectedStore.id,
            courierName: courier.name,
          },
        });
        break;
      case RESTAURANT_COURIER_AUTHENTICATION_TYPE.SINGLE_KEY:
        await authorizeCourier({
          variables: { ...authData, ...extraData, restaurantId: selectedStore.id, courierName: courier.name },
        });
        break;

      default:
    }
  };

  const onClickSubmit = e => {
    const { isAutoAccept, cardondeliveryPaymentEnabled } = settings.settings;

    if (
      (integrationsConstraints?.indexOf('isAutoAccept') > -1 && isAutoAccept) ||
      (integrationsConstraints?.indexOf('cardondeliveryPaymentEnabled') > -1 && cardondeliveryPaymentEnabled) ||
      (integrationsConstraints?.indexOf('taxPercentage') > -1 && selectedStore.taxPercentage)
    ) {
      e.preventDefault();
      notifications.show(
        <div className="flex flex-col">
          <Text
            value={
              cardondeliveryPaymentEnabled
                ? translations.ERROR_MESSAGE_COD(translate(titleParsedToArray))
                : isAutoAccept
                ? translations.ERROR_MESSAGE_AA(translate(titleParsedToArray))
                : translations.ERROR_MESSAGE_TAX(translate(titleParsedToArray))
            }
          />
          <div className={cx('mt-4', direction === 'rtl' ? 'ml-auto' : 'mr-auto')}>
            <Button kind="tertiary" size="sm" textColor="text-primary-base">
              <Text
                value={
                  cardondeliveryPaymentEnabled
                    ? translations.ERROR_CTA_COD
                    : isAutoAccept
                    ? translations.ERROR_CTA_AA
                    : translations.ERROR_CTA_TAX
                }
              />
            </Button>
          </div>
        </div>,
        'error',
        () =>
          navigate(
            replaceParams(
              cardondeliveryPaymentEnabled
                ? paths.paymentGateways
                : isAutoAccept
                ? paths.ordersManagement
                : paths.businessInformation,
            ),
          ),
      );
    } else {
      callAPI();
    }
  };

  return (
    <div style={{ direction }}>
      {courier.name === DELIVERECT_VAL && (
        <Deliverect
          courier={courier}
          onCancel={onCancel}
          closeable={closeable}
          changeSettings={changeSettings}
          defaultStep={defaultStep}
        />
      )}
      {courier.name === OCIMS && (
        <Ocims
          courier={courier}
          onCancel={onCancel}
          closeable={closeable}
          changeSettings={changeSettings}
          defaultStep={defaultStep}
        />
      )}
      {courier.name !== DELIVERECT_VAL && courier.name !== OCIMS && (
        <>
          <div className="px-4 whitespace-pre-wrap leading-relaxed">
            <Text className="mb-4" value={convertMultiLanguagesFromObjectToArray(description)} />

            {courierIntegrationInfo?.length &&
              courierIntegrationInfo.map(info => (
                <Fragment key={`text${info.header?.en}`}>
                  <Text className="font-bold mb-3" value={convertMultiLanguagesFromObjectToArray(info.header)} />
                  <Text className="mb-4" value={convertMultiLanguagesFromObjectToArray(info.body)} />
                </Fragment>
              ))}

            {courierDynamicSection?.length && (
              <DynamicSection
                setExtraData={setExtraData}
                courier={courier}
                courierDynamicSection={courierDynamicSection}
              />
            )}

            <IntegrationSection
              setAuthData={setAuthData}
              setIsDisabled={setIsDisabled}
              courier={courier}
              setIsLoading={setIsLoading}
            />
          </div>

          <div className="flex items-center pt-4 px-4 mt-4 border-t border-gray-300 justify-between pb-2 mb-12 sm:pb-0 sm:mb-0">
            {learnMore && <LearnMore link={lang === 'en' ? learnMore.en : learnMore.ar} />}

            <Stack direction="row">
              <Button onClick={onCancel} type="button" kind="tertiary">
                <Text value={translations.CANCEL} />
              </Button>

              <SubmitButton
                onClick={onClickSubmit}
                courier={courier}
                courierType={courierType}
                isLoading={isLoading}
                isDisabled={isDisabled}
                authorizeCourierLoading={authorizeCourierLoading}
                authorizeCourierBranchesLoading={authorizeCourierBranchesLoading}
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
};

ModalBody.propTypes = {
  onCancel: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      isAutoAccept: PropTypes.bool.isRequired,
      cardondeliveryPaymentEnabled: PropTypes.bool.isRequired,
    }),
  }),
  changeSettings: PropTypes.func.isRequired,
  closeable: PropTypes.func.isRequired,
  courier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    authenticationType: PropTypes.string.isRequired,
    integrationData: PropTypes.shape({
      courierBasicInfo: PropTypes.shape({
        title: PropTypes.shape({
          en: PropTypes.string.isRequired,
          ar: PropTypes.string.isRequired,
        }).isRequired,
        description: PropTypes.shape({
          en: PropTypes.string.isRequired,
          ar: PropTypes.string.isRequired,
        }).isRequired,
      }),
      integrationsConstraints: PropTypes.arrayOf(PropTypes.string),
      csOnly: PropTypes.bool,
      courierIntegrationInfo: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          body: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
        }),
      ),
      learnMore: PropTypes.shape({
        ar: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
      }),
      courierDynamicSection: PropTypes.arrayOf(
        PropTypes.shape({
          body: PropTypes.shape({
            template: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  courierType: PropTypes.string.isRequired,
  defaultStep: PropTypes.number,
};

export default ModalBody;
