import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { ORDER_STATUS } from 'constants/order';
import { Text } from 'components/service';
import { Button } from 'components/kit';

const StoreCourier = ({
  order,
  courier,
  assignedCourierId,
  currency,
  isCourierNotDeclinedTheRequest,
  onToggle,
  isExpectedAtValid,
  openOrderDeliveryConfirmationPopUp,
  quickAssignCourierId,
  handleAssignCourierToOrder,
  handleUnAssignCourierToOrder,
}) => {
  const { lang } = useContext(localeContext);
  const courierNameEn = courier.courierDetails.displayNameEn;
  const courierNameAr = courier.courierDetails.displayNameAr;
  const isCourierSupportCancellation = courier.supportCancellation;
  const isUnAssignCourier = true;
  const courierId = Number(courier?.courierId);
  const isArabic = lang === 'ar';
  const isSubmittedOrder = order.status === ORDER_STATUS.SUBMITTED;

  return (
    <div
      className={cx(
        'p-2 w-100 flex flex-1 border-b justify-between items-center',
        lang === 'ar' ? 'flex-row-reverse' : 'flex-row',
      )}
    >
      <span className="flex flex-col items-start" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
        <div className="flex text-sm text-gray-700">
          <div className={cx('w-6 h-6', isArabic ? 'ml-2' : 'mr-2')}>
            <img className="rounded-full border border-gray-100 shadow-sm" src={courier.logo} alt="kk" />
          </div>
          <span>{isArabic ? courierNameAr : courierNameEn}</span>
        </div>
        <div>
          {courier.courierEstimations?.supported ? (
            <>
              <Text
                className="text-xs text-gray-400 inline-block w-auto"
                value={translations.COURIERS_ESTIMATIONS}
                payload={courier.courierEstimations.deliveryTime}
              />
              <div>
                <Text
                  value={currency}
                  className={cx('text-xs text-gray-400 inline-block w-auto', lang === 'ar' ? 'ml-1' : 'mr-1')}
                />
                <span className="text-xs text-gray-400 inline-block w-auto">
                  {courier.courierEstimations.deliveryFees.toFixed(currency.decimals)}
                </span>
              </div>
            </>
          ) : (
            <Text className="text-xs text-gray-400 inline-block w-auto" value={translations.NO_COURIER_ESTIMATION} />
          )}
        </div>
      </span>
      {isSubmittedOrder && !assignedCourierId ? (
        courierId === quickAssignCourierId ? (
          <Text
            full={false}
            className={cx('text-s text-green-600', isArabic ? 'ml-6' : 'mr-6')}
            value={translations.SELECTED_COURIER}
          />
        ) : (
          <Button
            style={{
              width: '110px',
            }}
            kind="outline"
            weight="thin"
            onClick={() => {
              onToggle();
              !isExpectedAtValid || isCourierNotDeclinedTheRequest
                ? openOrderDeliveryConfirmationPopUp({
                    assignDriverToOrder: handleAssignCourierToOrder,
                    assignee: courier,
                  })
                : handleAssignCourierToOrder(courier);
            }}
            size="sm"
          >
            <Text className="text-xs" value={translations.ASSIGN} />
          </Button>
        )
      ) : courierId === assignedCourierId ? (
        <Button
          style={{
            width: '110px',
          }}
          kind="outline"
          weight="thin"
          onClick={() => {
            onToggle();
            isCourierNotDeclinedTheRequest
              ? openOrderDeliveryConfirmationPopUp({
                  assignDriverToOrder: handleUnAssignCourierToOrder,
                  assignee: courier,
                  isAssigneeSupportCancellation: isCourierSupportCancellation,
                  isUnAssignCourier,
                })
              : handleUnAssignCourierToOrder();
          }}
          size="sm"
        >
          <Text className="text-xs" value={translations.UNASSIGN} />
        </Button>
      ) : (
        <Button
          style={{
            width: '110px',
          }}
          kind="outline"
          weight="thin"
          onClick={() => {
            onToggle();
            !isExpectedAtValid || isCourierNotDeclinedTheRequest
              ? openOrderDeliveryConfirmationPopUp({
                  assignDriverToOrder: handleAssignCourierToOrder,
                  assignee: courier,
                })
              : handleAssignCourierToOrder(courier);
          }}
          size="sm"
        >
          <Text className="text-xs" value={translations.ASSIGN} />
        </Button>
      )}
    </div>
  );
};
StoreCourier.propTypes = {
  order: PropTypes.shape({
    number: PropTypes.string,
    id: PropTypes.number,
    deliveryCourier: {
      driverAssigned: PropTypes.bool,
      driverName: PropTypes.string,
      driverPhoneNumber: PropTypes.number,
      referenceId: PropTypes.string,
      externalOrderIdentifierLink: PropTypes.string,
      externalOrderIdentifierType: PropTypes.string,
      trackingLink: PropTypes.string,
      hasDriverInfo: PropTypes.bool,
      courierDetails: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        displayNameAr: PropTypes.string,
        displayNameEn: PropTypes.string,
      }),
    },
  }),
  courier: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    displayNameEn: PropTypes.string,
    displayNameAr: PropTypes.string,
  }),
  assignedCourierId: PropTypes.number,
  isCourierNotDeclinedTheRequest: PropTypes.bool,
  onToggle: PropTypes.func,
  isExpectedAtValid: PropTypes.bool,
  openOrderDeliveryConfirmationPopUp: PropTypes.func,
  quickAssignCourierId: PropTypes.number,
  handleAssignCourierToOrder: PropTypes.func,
  handleUnAssignCourierToOrder: PropTypes.func,
};

export default StoreCourier;
