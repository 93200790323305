import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import { ReactComponent as InfoIcon } from 'assets/icons-info.svg';
import { Button, Spinner, Stack } from 'components/kit';
import { useSelectedStore } from 'hooks';
import { Field, Text } from 'components/service';
import { Footer } from 'components/form/generic';
import { Select } from 'components/form/elements';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import * as translations from 'constants/translations';
import SyncMenuForm from './SyncMenuForm';
import { getFoodicsBranches } from '../../../../rest';
import { BULK_UPDATE_POS_BRANCHES } from './schemas';

const ImportFromFoodicsModal = ({ onCancel }) => {
  const { lang, translate } = useContext(localeContext);
  const { branches, pos } = useContext(userContext);
  const storeId = useSelectedStore();
  const notifications = useContext(notificationsContext);

  const MODAL_STEPS = { LINK_BRANCHES: 1, SYNC_MENU: 2 };

  const [currentModalStep, setCurrentModalStep] = useState(MODAL_STEPS.LINK_BRANCHES);
  const [foodicsBranches, setFoodicsBranches] = useState([]);
  const [calledFoodicsBranches, setCalledFoodicsBranches] = useState(false);

  let initialValues = {};
  branches.forEach(branch => {
    initialValues[`${branch.id}-externalId`] = branch.externalId;
  });

  const validate = (value, branchId, values) => {
    let errorMessage;
    const selectedFoodicsBranch = foodicsBranches.find(branch => branch.externalId === value);
    let branchesWithSameExternalId = Object.keys(values).filter(key => values[key] === value);
    if(selectedFoodicsBranch)
      if (branchesWithSameExternalId.length === 1) {
        if (branchesWithSameExternalId[0] !== `${branchId}-externalId`)
          errorMessage = translate(translations.BRANCH_ALREADY_LINKED(translate(selectedFoodicsBranch)));
      } else if (branchesWithSameExternalId.length > 1)
        errorMessage = translate(translations.BRANCH_ALREADY_LINKED(translate(selectedFoodicsBranch)));
    return errorMessage;
  };

  const [bulkUpdateBranchesExternalIds] = useMutation(BULK_UPDATE_POS_BRANCHES, {
    onCompleted: data => {
      if (data?.linkInternalBranchesToPosBranches.length > 0) {
        setCurrentModalStep(MODAL_STEPS.SYNC_MENU);
      } else {
        notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      }
    },
    onError: err => {
      const { body } = err.graphQLErrors[0].extensions.exception;
      for (let key of Object.keys(body)) {
        notifications.show(`${body[key][0]}`, "error");
      }
      if (!err.graphQLErrors) throw err;
    },
  });

  const onSubmitLinkBranches = async values => {
    const branchesLinks = Object.keys(values).map(key => ({
      storeId,
      branchInternalId: key.replace('-externalId', ''),
      branchExternalId: values[key],
      partnerName: pos.courierDetails.name
    }));
    
    bulkUpdateBranchesExternalIds({
      variables: {
        branchesLinks
      },
    });
  };

  useEffect(() => {
    const getFoodicsBranchesCall = async () => {
      try {
        const {
          data: { results: foodicsBranchesTemp },
        } = await getFoodicsBranches(storeId);
        setFoodicsBranches(
          foodicsBranchesTemp.map(foodicsBranch => ({
            externalId: foodicsBranch.id,
            titleEn: foodicsBranch.title_en,
            titleAr: foodicsBranch.title_ar,
            linkedTo: branches.find(branch => branch.externalId === foodicsBranch.id)?.id,
          })),
        );
        setCalledFoodicsBranches(true);
      } catch (error) {
        console.log(error);
      }
    };
    getFoodicsBranchesCall();
  }, []);

  return (
    <div>
      <div className="bg-gray-100 h-20 -mt-4 border-b border-gray-300 mb-2 flex flex-col w-full">
        <div className="flex flex-row mt-auto mb-2 mx-auto">
          <div
            className={cx(
              'w-2 h-2 rounded m-0 inline-flex',
              currentModalStep === MODAL_STEPS.LINK_BRANCHES && 'bg-primary-base',
              currentModalStep === MODAL_STEPS.SYNC_MENU && 'bg-gray-600',
            )}
          />
          <div className="flex-grow-0 w-40 h-px bg-gray-300 self-center mx-1" />
          <div
            className={cx(
              'w-2 h-2 rounded m-0 inline-flex',
              currentModalStep === MODAL_STEPS.SYNC_MENU && 'bg-primary-base',
              currentModalStep === MODAL_STEPS.LINK_BRANCHES && 'bg-gray-600',
            )}
          />
        </div>
        <div className="flex flex-row mb-auto mr-24 ml-24">
          <Text
            className={cx(
              'text-sm text-center',
              currentModalStep === MODAL_STEPS.LINK_BRANCHES && 'text-primary-base',
              currentModalStep === MODAL_STEPS.SYNC_MENU && 'text-gray-600',
            )}
            value={translations.LINK_BRANCHES}
          />
          <Text
            className={cx(
              'text-sm text-center',
              currentModalStep === MODAL_STEPS.SYNC_MENU && 'text-primary-base',
              currentModalStep === MODAL_STEPS.LINK_BRANCHES && 'text-gray-600',
            )}
            value={translations.SYNC_MENU}
          />
        </div>
      </div>
      <div className="h-16 -mt-4 border-b border-gray-300 mb-2 flex flex-row w-full px-4 pb-4 pt-5">
        <InfoIcon />
        <Text className="text-gray-600 text-xs pl-2" value={translations.IMPORT_FOOICS__DESCRIPTION} />
      </div>
      {currentModalStep === MODAL_STEPS.LINK_BRANCHES &&
        (!calledFoodicsBranches ? (
          <Spinner />
        ) : calledFoodicsBranches && foodicsBranches.length !== 0 ? (
          <Formik key="link-branches-formik" onSubmit={onSubmitLinkBranches} initialValues={initialValues}>
            {({ values, isSubmitting, handleChange }) => (
              <Form key="link-branches-form">
                {branches.map((branch, i) => (
                  <div
                    key={branch.id}
                    className={cx(
                      'px-4 flex flex-row',
                      i !== 0 && 'border-t border-gray-300',
                      i === 0 ? 'pt-2 pb-4' : branches.length === i + 1 ? 'pt-4' : 'py-4',
                    )}
                  >
                    <div className="flex-grow">
                      <span className="leading-10">{translate([branch.titleEn, branch.titleAr])}</span>
                    </div>
                    <div className="w-60 self-end">
                      <Field
                        name={`${branch.id}-externalId`}
                        onChange={e => handleChange(e)}
                        validate={value => validate(value, branch.id, values)}
                        value={values[`${branch.id}-externalId`] || ''}
                        stopValidateFunctionPropagation
                        component={Select}
                        type="squared"
                        required
                      >
                        {foodicsBranches.map(item => (
                          <option key={item.externalId} value={item.externalId}>
                            {translate(item)}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                ))}
                <Footer modalFooter>
                  <div className={cx(lang === 'ar' ? 'mr-auto' : 'ml-auto')}>
                    <Stack direction="row">
                      <Button data-testid="cancel-sort-catalog-btn" onClick={onCancel} type="button" kind="tertiary">
                        <Text value={translations.CANCEL} />
                      </Button>
                      <Button
                        data-testid="save-sort-catalog-btn"
                        kind="primary"
                        isSpinning={isSubmitting}
                        disabled={isSubmitting}
                      >
                        <Text value={translations.NEXT} />
                      </Button>
                    </Stack>
                  </div>
                </Footer>
              </Form>
            )}
          </Formik>
        ) : (
          <div>Empty State</div>
        ))}
      {currentModalStep === MODAL_STEPS.SYNC_MENU && <SyncMenuForm onCancel={onCancel} />}
    </div>
  );
};

ImportFromFoodicsModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default ImportFromFoodicsModal;
