import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  DISCOUNT,
  COUPON_DISCOUNT,
  SUBTOTAL_AFTER_VOUCHER,
  DELIVERY_FEES,
  TAXES,
  TOTAL,
  TAX_ARE_INCLUDED,
  PAYMENT_ORDER,
} from 'constants/translations';
import DELIVERY_TYPE_ENUM from './utils';

const OrderInvoicePricing = ({ order, isGift, direction, translate, lang, store, paidThroughMethod }) => {
  const {
    discountedAmount,
    voucherCode,
    subtotalAfterVoucher,
    deliveryType,
    deliveryFee,
    tax,
    taxPercentage,
    taxInclusive,
    total,
    paidThrough,
    voucherAmount,
  } = order || {};

  const { currency } = store || {};
  return (
    <div>
      {discountedAmount > 0 && !isGift && (
        <div className={cx('flex justify-between w-full pb-1 px-4', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-sm">{translate(DISCOUNT)}</span>
          <div className="text-sm" style={{ direction }}>
            <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>-
            {discountedAmount.toFixed(currency.decimals)}
          </div>
        </div>
      )}

      {voucherCode && !isGift && (
        <>
          <div className={cx('flex justify-between w-full pb-1 px-4', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-sm" style={{ direction }}>
              {translate(COUPON_DISCOUNT)}({voucherCode})
            </span>
            <div className="text-sm" style={{ direction }}>
              <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>-
              {voucherAmount.toFixed(currency.decimals)}
            </div>
          </div>

          <div className={cx('flex justify-between w-full pb-1 pt-2 px-4', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-base">{translate(SUBTOTAL_AFTER_VOUCHER)}</span>
            <div className="text-base" style={{ direction }}>
              <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
              {discountedAmount > 0
                ? (subtotalAfterVoucher - discountedAmount).toFixed(currency.decimals)
                : subtotalAfterVoucher.toFixed(currency.decimals)}
            </div>
          </div>
        </>
      )}

      {!isGift && (
        <div className="mb-3">
          {deliveryType !== DELIVERY_TYPE_ENUM.PICKUP && (
            <div className={cx('flex justify-between w-full pb-1 px-4', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-sm">{translate(DELIVERY_FEES)}</span>
              <div className="text-sm" style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {deliveryFee.toFixed(currency.decimals)}
              </div>
            </div>
          )}
          {!taxInclusive && (
            <div className={cx('flex justify-between w-full pt-2 px-4', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-sm">{translate(TAXES(taxPercentage))}</span>
              <div className="text-sm" style={{ direction }}>
                <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
                {tax.toFixed(currency.decimals)}
              </div>
            </div>
          )}
        </div>
      )}

      {!isGift && (
        <div
          className={cx(
            'flex justify-between bg-zyda-grey-50 w-full py-1 px-4 mb-2 text-base font-bold',
            lang === 'ar' && 'flex-row-reverse',
          )}
        >
          <span>{translate(TOTAL)}</span>
          <div style={{ direction }}>
            <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
            {total.toFixed(currency.decimals)}
          </div>
        </div>
      )}

      {!isGift && taxInclusive && (
        <div className={cx('flex justify-between w-full py-1 px-4 mb-3', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-sm">{translate(TAXES(taxPercentage))}</span>
          <div className="text-sm" style={{ direction }}>
            <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
            {tax.toFixed(currency.decimals)}
          </div>
        </div>
      )}

      {!isGift && (
        <div
          className={cx('flex bg-zyda-grey-50 w-full py-1 px-4 mb-2 text-base', lang === 'ar' && 'flex-row-reverse')}
        >
          <span className={cx('font-bold', lang === 'ar' ? 'ml-3' : 'mr-3')}>{translate(PAYMENT_ORDER)}</span>
          <span>{paidThroughMethod(paidThrough)}</span>
        </div>
      )}

      {!isGift && taxInclusive && (
        <div className={cx('flex w-full justify-center', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-sm">{translate(TAX_ARE_INCLUDED(taxPercentage))}</span>
        </div>
      )}
    </div>
  );
};

OrderInvoicePricing.propTypes = {
  order: PropTypes.shape({
    discountedAmount: PropTypes.number.isRequired,
    voucherCode: PropTypes.string,
    subtotalAfterVoucher: PropTypes.number,
    deliveryType: PropTypes.string,
    deliveryFee: PropTypes.number,
    tax: PropTypes.number,
    taxPercentage: PropTypes.number,
    taxInclusive: PropTypes.bool,
    total: PropTypes.number.isRequired,
    paidThrough: PropTypes.string,
    voucherAmount: PropTypes.number,
  }),
  isGift: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
  translate: PropTypes.func,
  lang: PropTypes.string.isRequired,
  store: PropTypes.shape({ currency: PropTypes.number.isRequired }),
  paidThroughMethod: PropTypes.func,
};
export default OrderInvoicePricing;
