import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

export default ({
  dropdownClassName,
  noOverflow,
  label,
  firstTitle,
  secondTitle,
  responsiveLabel,
  labelClassNames = 'text-zyda-grey-400',
  data,
  extraOptions,
  float,
  optionSelected,
  noBorder,
  noHover,
  noPlaceholder,
  bg,
  isOpen,
  onToggle,
  onSelect,
  scrollable,
  placeholder,
  full,
  position,
  combined,
  isDropUp,
  icon = 'keyboard_arrow_up',
  testId_openDropDown,
  testId_selectedData,
  testId_listData,
  shadow,
  noMargin,
  noPadding,
  isTab,
  isMultipleGroupOfOptions,
  containerStyle,
  listContainerStyle,
  wFull,
  children,
  itemColor,
  isTracked,
  alwaysShowIcon,
  isDisabled = false,
  isDeliveryZone = false,
}) => {
  const { lang, direction } = useContext(localeContext);
  const selectedItem =
    data?.find(item => item.id === optionSelected) ?? extraOptions?.find(item => item.id === optionSelected);
  const [color, setColor] = useState('border-gray-300');
  const dropdownIcon = (
    <i
      className={cx(
        'material-icons text-lg flex-2',
        !isTracked && (lang === 'ar' ? 'mr-auto' : 'ml-auto'),
        isTracked ? itemColor : 'text-gray-500 group-hover:text-primary-base',
      )}
    >
      {icon}
    </i>
  );
  return (
    <div
      className={cx(
        combined ? 'h-10' : noBorder && (!noMargin ? (lang === 'ar' ? 'ml-2' : 'mr-2') : ''),
        'flex-2 relative group float-' + float,
        bg ? ' bg-gray-200' : '',
        wFull && 'w-full',
      )}
      style={containerStyle}
    >
      <div
        className={cx(
          'flex items-center',
          !isDisabled && ' cursor-pointer',
          !isTab && 'py-2 h-10',
          !noBorder && 'border rounded-none ' + color,
        )}
        onFocus={() => setColor('border-primary-base')}
        onBlur={() => setColor('border-gray-300')}
      >
        <button
          data-testid={testId_openDropDown}
          className={cx(
            'outline-none flex items-center h-full w-full focus:outline-none',
            itemColor ? itemColor : 'text-zyda-black-100',
            !isTab && !noPadding && 'px-3',
          )}
          style={{ direction }}
          onClick={onToggle}
          type="button"
          disabled={isDisabled}
        >
          {label && (
            <span
              className={cx(
                'flex-2',
                [labelClassNames],
                responsiveLabel && 'hidden md:inline',
                lang === 'ar' ? 'ml-2' : 'mr-2',
              )}
            >
              {label}
            </span>
          )}
          {!children && (
            <span
              data-testid={testId_selectedData}
              className={cx(
                'flex whitespace-nowrap truncate',
                itemColor ? itemColor : 'text-zyda-black-100',
                lang === 'ar' ? 'ml-1' : 'mr-1',
              )}
            >
              {optionSelected
                ? selectedItem && selectedItem.title
                : placeholder
                ? placeholder
                : !noPlaceholder && <Text value={translations.PLEASE_SELECT} className="text-sm text-gray-500" />}
            </span>
          )}
          {alwaysShowIcon ? dropdownIcon : !isDisabled && dropdownIcon}
        </button>
      </div>
      <div
        className={cx(
          `items-center absolute bg-white mt-2 z-50`,
          !noOverflow && 'overflow-auto',
          scrollable && data && data.length > 5 && 'h-40',
          full ? 'w-full' : position && `${position}-0 max-w-xs w-auto`,
          isOpen ? 'visible' : 'invisible',
          isDropUp ? 'bottom-0 mb-12' : 'mt-2',
          !shadow && 'border',
        )}
        style={{
          boxShadow: shadow ? '0 -2px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06)' : 'none',
          ...listContainerStyle,
        }}
      >
        {!children && (
          <ul className={cx('list-reset py-2', scrollable ? 'pl-2 pr-4' : 'px-2', dropdownClassName)}>
            {label && responsiveLabel && (
              <li
                disabled
                className={cx(
                  'px-2 py-1 block md:hidden text-gray-700 hover:bg-primary-50 rounded whitespace-nowrap',
                  lang === 'ar' ? 'text-right' : 'text-left',
                  !full && 'truncate',
                  !noOverflow && 'overflow-auto',
                  !isDisabled && 'cursor-pointer',
                )}
              >
                {label}
              </li>
            )}
            <div className={cx(secondTitle && 'mb-4')}>
              {secondTitle && <span>{secondTitle}</span>}
              {isMultipleGroupOfOptions &&
                extraOptions.map((item, i) => {
                  const isSelected = item.id === optionSelected;
                  return (
                    <DefaultDropdownItem
                      key={i}
                      item={item}
                      isSelected={isSelected}
                      noHover={noHover}
                      onSelect={onSelect}
                      onToggle={onToggle}
                      lang={lang}
                      full={full}
                      testId_listData={testId_listData}
                      isDisabled={isDisabled}
                      isDeliveryZone={isDeliveryZone}
                    />
                  );
                })}
            </div>
            <>
              {firstTitle && <span>{firstTitle}</span>}
              {data.map((item, i) => {
                const isSelected = item.id === optionSelected;
                return (
                  <DefaultDropdownItem
                    key={i}
                    item={item}
                    isSelected={isSelected}
                    noHover={noHover}
                    onSelect={onSelect}
                    onToggle={onToggle}
                    lang={lang}
                    full={full}
                    testId_listData={testId_listData}
                    isDisabled={isDisabled}
                    isDeliveryZone={isDeliveryZone}
                  />
                );
              })}
            </>
          </ul>
        )}
        {children}
      </div>
    </div>
  );
};

export const DefaultDropdownItem = ({
  item,
  key,
  isSelected,
  onSelect,
  noHover,
  onToggle,
  lang,
  full,
  testId_listData,
  isDisabled,
  isDeliveryZone,
}) => (
  <li
    key={key}
    id={item.id}
    data-testid={testId_listData}
    className={cx(
      'px-2 py-1 block text-zyda-black-100 rounded text-sm',
      !noHover && 'hover:bg-primary-50',
      isSelected && 'selected',
      lang === 'ar' ? 'text-right' : 'text-left',
      !full && 'truncate',
      !isDisabled && 'cursor-pointer',
      !isDeliveryZone && 'rounded',
      isDeliveryZone && item.color && (lang === 'ar' ? 'border-r-2 mb-1' : 'border-l-2 mb-1'),
    )}
    style={{ borderColor: isDeliveryZone && item.color }}
    onClick={() => !item.body && onSelect(item.id)}
    name={
      typeof item.title === 'object'
        ? item.title.props.value instanceof Array
          ? item.title.props.value[lang === 'en' ? 0 : 1]
          : item.title.props.name
          ? item.title.props.name.props.value[lang === 'en' ? 0 : 1]
          : lang === 'en'
          ? item.title.props.value.titleEn
          : item.title.props.value.titleAr
        : item.title
    }
  >
    {(item.body && item.body(onToggle)) || item.title}
  </li>
);
