import GoogleTagManagerIcon from 'assets/google-tag-manager.svg';
import GoogleAnalyticsIcon from 'assets/google-analytics-image.svg';
import FacebookPixelIcon from 'assets/facebook-pixel-image.svg';
import SnapchatIcon from 'assets/snapchat.png';

import {
  GOOGLE_ANALYTICS,
  GOOGLE_TRACKING_ID,
  GOOGLE_TRACKING_ID_LIKE,
  GTM_TITLE,
  GTM_TRACKING_ID,
  GTM_TRACKING_ID_LIKE,
  FACEBOOK_PIXEL,
  FACEBOOK_TRACKING_ID,
  FACEBOOK_TRACKING_ID_LIKE,
  SNAPCHAT_PIXEL,
  SNAPCHAT_PIXEL_DESCRIPTION,
  SNAPCHAT_TRACKING_ID_LIKE,
} from 'constants/translations';

export const TrackingIntegrations = {
  FACEBOOK_PIXEL: {
    id: 'FACEBOOK_PIXEL',
    trackerName: 'fbadTrackId',
    imageSrc: FacebookPixelIcon,
    title: FACEBOOK_PIXEL,
    subtitle: FACEBOOK_TRACKING_ID,
    inputSubtitle: FACEBOOK_TRACKING_ID_LIKE,
  },
  GOOGLE_TAG_MANAGER: {
    id: 'GOOGLE_TAG_MANAGER',
    trackerName: 'gtmTrackId',
    imageSrc: GoogleTagManagerIcon,
    title: GTM_TITLE,
    subtitle: GTM_TRACKING_ID,
    inputSubtitle: GTM_TRACKING_ID_LIKE,
  },
  UNIVERSAL_ANALYTICS: {
    id: 'UNIVERSAL_ANALYTICS',
    trackerName: 'gaTrackId',
    imageSrc: GoogleAnalyticsIcon,
    title: GOOGLE_ANALYTICS,
    subtitle: GOOGLE_TRACKING_ID,
    inputSubtitle: GOOGLE_TRACKING_ID_LIKE,
  },
  SNAPCHAT_PIXEL: {
    id: 'SNAPCHAT_PIXEL',
    trackerName: 'snapTrackId',
    imageSrc: SnapchatIcon,
    title: SNAPCHAT_PIXEL,
    subtitle: SNAPCHAT_PIXEL_DESCRIPTION,
    inputSubtitle: SNAPCHAT_TRACKING_ID_LIKE,
  },
};
